import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {to_specs} from '@bitstillery/common/lib/specs'
import {merge_deep} from '@bitstillery/common/lib/utils'
import {
    Button,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Country,
    PanelFilters,
    RowActionDelete,
    RowActionEdit,
} from '@bitstillery/common/components'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {api, notifier, events} from '@bitstillery/common/app'

import {ProductAudit} from './product_audit'

import {model} from '@/market/pricelists/list_pricelist_items'
import {PanelSpli} from '@/market/pricelists/components/panel_spli'
import {$s} from '@/app'

export const collection = new CollectionProxy()
window.collection = collection

const columns = [
    {
        name: 'Product',
        render: (row) => {
            const country = row.country_of_origin ? row.country_of_origin : row.default_country_code
            return <div className="td-group">
                <span className="header">
                    {country && <Country
                        className="mr-05"
                        country_code={country}
                    />}
                    {row.product_name}
                    :{row.artkey}

                </span>
                {(() => {
                    const item = {
                        bottle_alcohol_percentage: row.alcohol_percentage,
                        bottle_refill_status: row.refill_status,
                        bottle_volume: row.volume,
                        case_number_of_bottles: row.number_of_bottles_per_case,
                        case_gift_box_type: row.gift_box_type,
                        case_customs_status: row.customs_status,
                    }

                    return <span className="details">{to_specs(item)}</span>
                })()}
            </div>
        },
    }, {
        name: 'Matching Score',
        render: (row) => {
            return row.matching_score ? `${row.matching_score}%` : '-'
        },
    },
]

export class SpliUnresolved extends MithrilTsxComponent<any> {

    onresolve:Function

    async oninit() {
        await collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: `discover/supplier-price-lists/${m.route.param('artkey')}/items/unresolved/collection-view`,
            },
        })
        collection.events.once('state_items_updated', () => {
            if (collection.state.items.length) {
                merge_deep($s.context, {
                    id: collection.state.items[0].artkey,
                    data: collection.state.items[0],
                    name: 'edit_spli',
                })
            }
        })
    }

    google_images_url(row) {
        const query = encodeURIComponent(row.product_name)
        return `https://www.google.com/search?tbm=isch&q=${query}`
    }

    google_url(row) {
        const query = encodeURIComponent(row.product_name)
        return `https://www.google.com/search?q=${query}`
    }

    view() {
        return <div className="c-spli-unresolved view-container">
            <PanelFilters collection={collection} />
            <CollectionView mode="table">
                <CollectionHeader collection={collection} columns={columns} />
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    row_actions={(row) => {
                        return [
                            <RowActionEdit
                                collection={collection}
                                context={{name: 'resolve_line', title: 'Edit Source line'}}
                                icon=""
                                mode="panel"
                                onactivate={(row) => {
                                    collection.state.detail = row.artkey
                                }}
                                row={row}
                            />,
                            <Button
                                icon="search_web"
                                link={this.google_url(row)}
                                tip={() => 'Google search this source line'}
                                variant="toggle"
                            />,
                            <Button
                                icon="image"
                                link={this.google_images_url(row)}
                                tip={() => 'Google image search this source line'}
                                variant="toggle"
                            />,
                            <RowActionDelete
                                row={row}
                                row_delete={async() => {
                                    collection.select_next(row.artkey)
                                    await api.post('matcher.blacklist_usplsl', {
                                        blacklisted: true,
                                        usplsl_artkey: row.artkey,
                                        resolving_user_artkey: $s.identity.artkey,
                                    }, false)

                                    notifier.notify('Sourceline is now on the ignore list', 'warning')
                                    events.emit('spl:refetch')
                                }}
                                tip="Put sourceline on ignore list"
                                variant="deactivate"
                            />,
                        ]
                    }}
                    row_detail={(row) => {
                        return <ProductAudit
                            audit_endpoint={`discover/supplier-price-lists/${model.supplier_pricelist.artkey}/source-lines/${row.artkey}/audit-logs`}
                            header={model.supplier_pricelist.header_as_list}
                            line_content={row.line_content}
                            type="warning"
                        />
                    }}
                />
            </CollectionView>
            <PanelSpli
                collection={collection}
                icon="list"
            />
        </div>
    }
}
