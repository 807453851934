import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Icon, Link} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {to_specs} from '@bitstillery/common/lib/specs'
import {api} from '@bitstillery/common/app'

const ICON_TYPE = {
    danger: 'danger',
    success: 'checked',
    warning: 'warning',
}
interface ProductAuditAttrs {
    audit_endpoint?: string
    audit_logs?: []
    header: string[]
    line_content: string
    specs?: {}
    type: string
}

export class ProductAudit extends MithrilTsxComponent<ProductAuditAttrs> {

    data = proxy({
        audit_logs: [],
    })

    async oninit(vnode:m.Vnode<ProductAuditAttrs>) {
        let audit_logs:[]
        if (vnode.attrs.audit_endpoint) {
            ({result: audit_logs} = await api.get(vnode.attrs.audit_endpoint) as any)
        } else {
            audit_logs = vnode.attrs.audit_logs
        }

        this.data.audit_logs.splice(0, this.data.audit_logs.splice.length, ...audit_logs)
    }

    view(vnode: m.Vnode<ProductAuditAttrs>): m.Children {
        const row_values = vnode.attrs.line_content?.split(';')
        return <div className={classes('c-sourceline-info', 'no-click', `type-${vnode.attrs.type}`)}>
            <div className="image-suggestions">
                <Icon name="image" size="xxl" type="unset" />
            </div>
            <div className="source">
                <div className="audit-logs">
                    <div className="header">
                        <Icon
                            name={ICON_TYPE[vnode.attrs.type]}
                            tip={() => {
                                if (vnode.attrs.type === 'success') {
                                    return this.data.audit_logs.map((i:any) => i.message).join('\n')
                                }
                                return 'Unresolved product'
                            }}
                            type="unset"
                        />
                    </div>

                    <div className="details">
                        {vnode.attrs.specs ? [
                            <Link href={`data/products/${vnode.attrs.specs.product_artkey}`} target="_blank" type="success">{vnode.attrs.specs.product_name}</Link>,
                            <span>{to_specs(vnode.attrs.specs)}</span>,
                        ] : this.data.audit_logs.map((i) => {
                            return <div className="audit-line">{i.message}</div>
                        })}
                    </div>
                </div>

                <div className="cells">
                    {vnode.attrs.header.map((cell, index) => {
                        return <div className="cell">
                            <div className="key">{cell}</div>
                            <div className="value">{row_values[index]}</div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    }
}
