import {Observable} from 'rxjs'
import {map} from 'rxjs/operators'
import {download_binary_file_from_base64_str} from 'utils.ls'

import {Api, FactserverEmptyResponse, FactserverRequestData} from './api'
import {Account} from './relation_api'
import {
    GetBuyFromAccountRelatedSalesOrderResponse,
    GetHiddenInformationResponse,
    GetPurchaseOrderResponse,
    GetRelatedSalesOrderItemResponse, RefillStatus,
} from './fact2server_api'

interface PurchaseKeyFiguresResponse {
    amount_of_saved_and_confirmed_purchase_orders: number
    number_of_cases_overdue: number
    number_of_cases_this_month: number
    number_of_cases_this_week: number
    number_of_confirmed_purchase_orders: number
    number_of_saved_purchase_orders: number
    number_of_open_tbos: number
}

export interface InstockLoenderslootInspectionItemResponse {
    message: string
}

export interface PurchaseOrder {
    artkey: number
    account_artkey: number
    account: Account
    arrival_notice_comment: string
    supplier: {
        name: string
        street_address: string
        zip_code: string | null
        city: string
        country_code: string
        relation_nr: string
        price_preference: string
    }
    supplier_artkey: number
    incoterm: string
    incoterm_location: string
    created_on: string
    reference: string
    supplier_reference: string
    target_warehouse_artkey: number
    target_warehouse: {
        artkey: number
        city: string
        country_code: string
        currency: string
        excise_id: string | null
        warehouse_id: string
        name: string
        street_address: string
        vat_id: string
        zip_code: string
    }
    expected_delivery_date: string
    warehouse_reference: string
    was_handled_by_artkey: number
    was_bought_in: string
    was_bought_for: number
    number_of_cases: number
    status: string
    bought_against_rate: number
    is_cancelled: boolean
    warehouse_base_costs: number
    warehouse_costs_per_case: number
    transport_costs: number
    waste_fund_costs: number
    insurance_type_artkey: number
    remark: string
    has_attachments: boolean
    supplier_invoice_reference: string
    requires_import_statement: boolean
    has_import_statement: boolean
    rfp_comment: string
}

export type PurchaseOrderResponse = PurchaseOrder
export type GetPurchaseOrdersResponse = PurchaseOrder

export interface PurchaseOrderWithItemsResponse extends PurchaseOrderResponse {
    purchase_order_items: Array<PurchaseOrderItemResponse>
}

export interface PurchaseOrderItemResponse {
    artkey: number
    case_artkey: number
    purchase_order_item_number: number
    case: {
        artkey: number
        bottle: {
            artkey: number
            product: Product
            alcohol_percentage: string
            refill_status: string
            volume: string
            loendersloot_id: string
        }
        number_of_bottles: number
        customs_status: string
        gift_box_type: string
        tax_label: string
        item_tags: string[] | null
        best_before_date: string
    }
    number_of_cases: number
    is_in_stock: boolean
    is_in_stock_loendersloot: boolean
    was_bought_for: number
    show_bid_price_on_rfp: boolean
    show_as_new_on_rfp: boolean
    cases_per_pallet: number | null
    remark: string | null
    comment: string | null
    refers_to: {
        artkey: number
        price_per_case: number
        price_per_bottle: number
        number_of_bottles_per_case: number
    } | null
    refers_to_spli_price_per_case: string | null
    suggested_price_per_case: number | null
    country_of_origin: string | null
    items: Array<ItemResponse>
}

interface Product {
    artkey: number
    name: string

    product_category: {
        artkey: number
    }
}

export interface ItemResponse {
    case: Record<string, unknown>
    number_of_cases: number
    country_of_origin: string | null
}

export function total_number_of_cases_in_items(poi: PurchaseOrderItemResponse): number {
    let total_number_of_cases = 0
    for (const item of poi.items) {
        if (item.number_of_cases) {
            total_number_of_cases += +item.number_of_cases
        }
    }
    return total_number_of_cases
}

interface SplitTboToNewTboRequest extends FactserverRequestData {
    tbo_item_artkey: number
    number_of_cases: number
    lower_number_of_cases_in_poi: boolean
}

export interface GetPurchaseOrderItemsByBottleRequest extends FactserverRequestData {
    bottle_artkey?: number
    ignore_ref?: boolean
    case_artkey?: number
    supplier_artkey?: number
    limit?: number
    offset?: number
}

export interface GetPurchaseOrderItemByBottleResponse {
    total: number
    purchase_order_items: PurchaseOrderItemByBottle[]
}

export interface PurchaseOrderItemByBottle {
    artkey: number

    was_bought_for: number
    price_per_bottle: number
    number_of_cases: number

    purchase_order_artkey: number
    purchase_order_reference: string
    purchase_order_status: string
    purchase_order_created_on: string
    purchase_order_latest_status_update: string
    purchase_order_was_bought_in: string
    purchase_order_bought_against_rate: number

    supplier_name: string
    account_name: string
    account_slug: string

    bottle_refill_status: string
    bottle_alcohol_percentage: string
    bottle_volume: string
    case_artkey: number
    case_number_of_bottles: number
    case_excise_nl: number
    case_gift_box_type: string
    case_tax_label: string
    case_customs_status: string

    profile_name: string
}

export interface MarkUpIsUpEntryAsUnseenRequest extends FactserverRequestData {
    bottle_artkey: number
}

export interface MarkUpIsUpEntryAsSeenRequest extends FactserverRequestData {
    bottle_artkey: number
    snooze_until_availability?: number
    snooze_until_date?: string
}

export interface MarkUpIsUpEntryAsSeenResponse extends FactserverRequestData {
    snooze_until_availability?: number
    snooze_until_date?: string
}

export interface GetLoenderslootInspectionItemResponse {
    artkey: number
    description: string
    created_on: string
    loendersloot_id: string
    loendersloot_article_id: string
    lot: string
    bottle_gtin_code: string
    case_gtin_code: string
    giftbox_gtin_code: string
    hs_code: string
    country_of_origin: string
    number_of_bottles: number
    bottles_per_case: number
    alcohol_percentage: number
    case_length: number
    case_width: number
    case_height: number
    other: string
    in_stock_on: string

    bottle_artkey: number
    bottle_volume: number | null
    bottle_alcohol_percentage: string
    bottle_refill_status: string
    product_name: string

    item_artkey: number
    item_lot: string
    purchase_order_item_artkey: number
    has_gift_box: boolean
    best_before_date?: string
    loendersloot_tags: string[]
    is_showing_photos?: boolean;
}

export interface PurchaseOrderItemConflictInStock {
    artkey?: number | null
    product?: string | null
    category_artkey?: number | null
    volume?: number | null
    alcohol_percentage?: number | null
    refill_status?: string | null

    bottles_per_case?: number | null
    gift_box_type?: string | null
    number_of_cases?: number | null
    price_per_case?: number | null

    customs_status?: string | null
    tax_label?: string | null
    remark?: string | null
    country_of_origin?: string | null
}

export interface UpdatePurchaseOrderItemRequest {
    artkey: number
    bottle_artkey: number
    volume: number,
    alcohol_percentage: string,
    refill_status: RefillStatus,
    number_of_cases: number
    number_of_bottles_per_case: number
    was_bought_for: string
    suggested_sales_price_per_case?: string
    customs_status: string
    gift_box_type?: string | null
    comment?: string
    tax_label?: string
    item_tags: number[]
    best_before_date?: string | null
    cases_per_pallet?: number
    remark?: string
    bottle_gtin_code?: string
    case_gtin_code?: string
    country_of_origin?: string
}

export interface SendToLoenderslootRequest {
    purchase_order_artkey: number
    expected_delivery_date: string
    warehouse_instruction?: string
    freight_instruction?: string
    frontoffice_instruction?: string
    number_of_block_pallets?: number
    number_of_euro_pallets?: number
}

export class PurchaseApi {
    api: Api

    constructor() {
        this.api = new Api()
    }

    get_loendersloot_inspection_items(
        purchase_order_artkey: number,
        only_with_issues: boolean,
    ): Observable<GetLoenderslootInspectionItemResponse[]> {
        return this.api.post_request('purchase.loendersloot.get_loendersloot_inspection_items', {
            filters: {
                purchase_order_artkey: purchase_order_artkey,
                only_with_issues: only_with_issues,
            },
        })
    }

    get_purchase_order_items_by_bottle(
        request: GetPurchaseOrderItemsByBottleRequest,
    ): Observable<GetPurchaseOrderItemByBottleResponse> {
        return this.api.post_request('purchase.items.get_purchase_order_items_by_bottle', request)
    }

    instock_conflict_loendersloot_inspection_item(
        purchase_order_artkey: number,
        loendersloot_inspection_item_artkey: number,
        purchase_order_item: PurchaseOrderItemConflictInStock,
    ): Observable<null> {
        return this.api.post_request('purchase.loendersloot.instock_conflict_loendersloot_inspection_item', {
            purchase_order_artkey: purchase_order_artkey,
            loendersloot_inspection_item_artkey: loendersloot_inspection_item_artkey,
            purchase_order_item: purchase_order_item,
        })
    }

    instock_loendersloot_inspection_item(
        loendersloot_inspection_item_artkey: number,
        purchase_order_item_artkey: number,
        update_poi: boolean,
        missing_eu_address: boolean,
        override_refill_status: string | null,
        override_gift_box: string | null,
    ): Observable<InstockLoenderslootInspectionItemResponse> {
        return this.api.post_request('purchase.loendersloot.instock_loendersloot_inspection_item', {
            loendersloot_inspection_item_artkey: loendersloot_inspection_item_artkey,
            purchase_order_item_artkey: purchase_order_item_artkey,
            update_poi: update_poi,
            missing_eu_address: missing_eu_address,
            override_refill_status: override_refill_status,
            override_gift_box: override_gift_box,
        })
    }

    instock_purchase_order(purchase_order_artkey: number): Observable<null> {
        return this.api.post_request('purchase.core.instock_purchase_order', {
            purchase_order_artkey: purchase_order_artkey,
        })
    }

    onhold_purchase_order(purchase_order_artkey: number): Observable<null> {
        return this.api.post_request('purchase.loendersloot.onhold_purchase_order', {
            purchase_order_artkey: purchase_order_artkey,
        })
    }

    key_figures(): Observable<PurchaseKeyFiguresResponse> {
        return this.api.post_request('purchase.reporting.get_key_figures', {})
    }

    mark_up_is_up_entry_seen(request: MarkUpIsUpEntryAsSeenRequest): Observable<MarkUpIsUpEntryAsSeenResponse> {
        return this.api.post_request('purchase.up_is_up.mark_up_is_up_entry_seen', request)
    }

    mark_up_is_up_entry_unseen(request: MarkUpIsUpEntryAsUnseenRequest): Observable<null> {
        return this.api.post_request('purchase.up_is_up.mark_up_is_up_entry_unseen', request)
    }

    onhold_loendersloot_inspection_item(loendersloot_inspection_item_artkey: number): Observable<null> {
        return this.api.post_request('purchase.loendersloot.onhold_loendersloot_inspection_item', {
            loendersloot_inspection_item_artkey: loendersloot_inspection_item_artkey,
        })
    }

    purchase_order_with_items(purchase_order_artkey: number): Observable<Array<PurchaseOrderWithItemsResponse>> {
        return this.api.post_request('purchase.core.get_purchase_orders_with_items', {
            purchase_order_artkey: purchase_order_artkey,
        })
    }

    split_tbo_to_new_tbo(request: SplitTboToNewTboRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('purchase.core.split_tbo_to_new_tbo', request)
    }

    update_arrival_notice(purchase_order_artkey: number, comment: string): Observable<FactserverEmptyResponse> {
        return this.api.post_request('purchase.core.update_arrival_notice_comment', {
            artkey: purchase_order_artkey,
            arrival_notice_comment: comment,
        })
    }

    update_remark(purchase_order_artkey: number, remark: string): Observable<FactserverEmptyResponse> {
        return this.api.post_fact2server_request(`discover/purchase-orders/${purchase_order_artkey}/update-remark`, {
            remark: remark,
        })
    }

    get_purchase_order(purchase_order_artkey: number): Observable<GetPurchaseOrderResponse> {
        return this.api.get(`discover/purchase-orders/${purchase_order_artkey}`)
    }

    export_to_excel(purchase_order_artkey: number, file_name: string): Observable<void> {
        return this.api.post_request('purchase.reporting.export_purchase_order_to_excel', {
            purchase_order_artkey: purchase_order_artkey,
        }).pipe(
            map((response) => download_binary_file_from_base64_str(response.file_base64_encoded, file_name)),
        )
    }

    export_stock_differences_to_excel(purchase_order_artkey: number, file_name: string): Observable<void> {
        return this.api.post_request('purchase.reporting.export_stock_differences_to_excel', {
            purchase_order_artkey: purchase_order_artkey,
        }).pipe(
            map((response) => download_binary_file_from_base64_str(response.file_base64_encoded, file_name)),
        )
    }

    unconfirm_purchase_order(purchase_order_artkey: number): Observable<any> {
        return this.api.post_request('purchase.core.unconfirm_purchase_order', {
            purchase_order_artkey: purchase_order_artkey,
        })
    }

    toggle_is_communicated(purchase_order_artkey: number): Observable<any> {
        return this.api.post_request('purchase.core.toggle_is_communicated', {
            purchase_order_artkey: purchase_order_artkey,
        })
    }

    purchase_order_item_related_soi(purchase_order_artkey: number, purchase_order_item_artkey: number): Observable<GetRelatedSalesOrderItemResponse[]> {
        return this.api.get(`discover/purchase-orders/${purchase_order_artkey}/items/${purchase_order_item_artkey}/sales-order-items`)
    }

    purchase_order_item_related_tbo(purchase_order_artkey: number, purchase_order_item_artkey: number): Observable<GetRelatedSalesOrderItemResponse[]> {
        return this.api.get(`discover/purchase-orders/${purchase_order_artkey}/items/${purchase_order_item_artkey}/sales-order-tbo-items`)
    }

    purchase_order_item_hidden_information(purchase_order_artkey: number, purchase_order_item_artkey: number): Observable<GetHiddenInformationResponse> {
        return this.api.get(`discover/purchase-orders/${purchase_order_artkey}/items/${purchase_order_item_artkey}/hidden-information`)
    }

    delete_hidden_country_for_item(purchase_order_artkey: number, purchase_order_item_artkey: number, country_code: string): Observable<any> {
        return this.api.delete(`discover/purchase-orders/${purchase_order_artkey}/items/${purchase_order_item_artkey}/hidden-information/countries/${country_code}`)
    }

    delete_hidden_relation_for_item(purchase_order_artkey: number, purchase_order_item_artkey: number, relation_artkey: number): Observable<any> {
        return this.api.delete(`discover/purchase-orders/${purchase_order_artkey}/items/${purchase_order_item_artkey}/hidden-information/relations/${relation_artkey}`)
    }

    add_hidden_country_for_item(purchase_order_artkey: number, purchase_order_item_artkey: number, country_code: string): Observable<any> {
        return this.api.post_fact2server_request(`discover/purchase-orders/${purchase_order_artkey}/items/${purchase_order_item_artkey}/hidden-information/countries`, {
            country_code: country_code,
        })
    }

    add_hidden_relation_for_item(purchase_order_artkey: number, purchase_order_item_artkey: number, relation_artkey: number): Observable<any> {
        return this.api.post_fact2server_request(`discover/purchase-orders/${purchase_order_artkey}/items/${purchase_order_item_artkey}/hidden-information/relations`, {
            relation_artkey: relation_artkey,
        })
    }

    send_purchase_order_to_loendersloot(send_to_loendersloot_request: SendToLoenderslootRequest): Observable<any> {
        return this.api.post_request('loendersloot.messaging.send_purchase_order_xml_to_loendersloot', send_to_loendersloot_request)
    }

    get_buy_from_account_origin_sales_order(po_artkey: number): Observable<GetBuyFromAccountRelatedSalesOrderResponse> {
        return this.api.get(`discover/purchase-orders/${po_artkey}/buy-from-account-related-sales-order`)
    }
}
