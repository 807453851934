m = require 'mithril'
{a}: utils = require 'utils.ls'
api = require 'api.ls'
discover_link = require '@/market/pricelists/components/discover_link'
items_table = require './components/items_table.ls'
{ItemForm} = require './components/item_form.ls'
inputs = require '@/components/inputs'
{Button} = require '@bitstillery/common/components'
{Item, ItemMutationReason} = require '@/models/stock'
app = require('@/app')

module.exports = class MutationCreateUpdateCases
    ->
        item_reference = m.route.param 'reference'
        @item = window.prop new Item
        @items = window.prop []
        @base_item_json = window.prop {}
        @updated_item = window.prop new Item

        @new_items = window.prop []

        @_number_of_cases = window.prop ''
        @reason = window.prop ''
        @description = window.prop ''

        @get_item item_reference

    create_base_item: (json) ~>
        @base_item_json json
        @base_item_json!lot = ''
        @base_item_json!number_of_bottles_per_case = ''
        @base_item_json!number_of_cases_in_stock = ''

    get_item: (item_reference) ~>
        data = do
            item_reference: item_reference

        api.call2 'stock.get_item', data, (resp) ~>
            @item new Item resp.result
            @items [@item!]
            @updated_item new Item resp.result
            @create_base_item resp.result

    number_of_cases: (number_of_cases) ~>
        if number_of_cases?
            @_number_of_cases number_of_cases
            @updated_item!number_of_cases_in_stock(+@_number_of_cases! + +@item!number_of_cases_in_stock!)
            @updated_item!number_of_cases_available(+@_number_of_cases! + +@item!number_of_cases_available!)
        else
            return @_number_of_cases!

    create_mutation: (e) ~>
        e.preventDefault!

        new_items = [item!toObject! for item in @new_items!]

        data = do
            user_artkey: app.$s.identity.artkey
            item_mutation_reason: @reason!
            description: @description!
            item_artkey: +@item!artkey!
            number_of_cases: +@_number_of_cases!
            new_items: new_items
        api.call 'stock.create_item_mutation_update_cases', data, @handle_create_mutation

    handle_create_mutation: (resp) ~>
        if resp.success
            app.$m.common.observable.broadcast 'stock_updated', ''
            app.notifier.notify 'Created mutation successfully', 'success'
            m.route.set "/stock/mutations/manage/#{resp.result}"
        else
            app.notifier.notify resp.message, 'danger'

    view: -> m '.c-create-mutation view' a do
        m '.btn-toolbar',
            m Button, {
                active: false,
                icon: 'back',
                onclick: ~> m.route.set "/stock/manage/#{@item!reference!}"
                variant: 'toggle'
            }

        m '.panel.panel-primary' a do
            m '.panel-heading' m '.panel-title' 'Current stock'
            m '.panel-body' a do
                items_table.items_table(@items)

        m 'form.flex-form' {onsubmit: @create_mutation} a do
            m ItemForm, do
                item: @updated_item
                new_items: @new_items
                base_item_json: @base_item_json
                fields: ['lot', 'case', 'stock', 'number_of_bottles_per_case']
                reason: @reason

            m '.fieldset',
                m '.field' a do
                    m 'label' 'Difference in cases'
                    inputs.number(@number_of_cases, {required: true})
                    m '.help' 'New stock: ', m 'strong', (+@number_of_cases! + +@item!number_of_cases_in_stock!)

                m '.field' a do
                    m 'label' 'Reason'
                    inputs.select(@reason, ItemMutationReason.update_cases_options, {required: true})
                m '.field' a do
                    m 'label' 'Description'
                    inputs.textarea(@description, {required: true})
            m '.btn-toolbar',
                m Button, {
                    className: 'btn-submit',
                    onclick: @create_mutation,
                    text: 'Update Cases',
                    type: 'success',
                }

