m = require 'mithril'
{map} = require 'prelude-ls'
utils = require 'utils.ls'
{debounce} = require '@bitstillery/common/ts_utils'
{RelationMultiSelectDropDown} = require '@/components/relation'
{RelationDropDownData} = require '@/factserver_api/relation_api'
{filter} = require 'rxjs/operators'
{validate_bottle_gtin} = require '@/components/validate_gtin.ls'
{Country} = require '@bitstillery/common/components'
api = require 'api.ls'
{Amount} = require '@bitstillery/common/components'
inputs = require '@/components/inputs'
{MoneyInput} = require '@/components/decimal_input'
{pricelist_link} = require 'data/components/pricelist_link.ls'
discover_link = require '@/market/pricelists/components/discover_link'
table-base = require '@/components/table/base.ls'
{Product} = require '@/models/products'
{button-with-icon, icon-button} = require '@/components/buttons.ls'
{CountriesMultiSelect, CountriesSelect, empty_option} = require '@/components/html_components'
{AutocompleteInput} = require '@/components/collection/autocomplete_input'
{ProductManagementApi} = require '@/factserver_api/product_management_api'
{Spinner} = require '@bitstillery/common/components'
{PurchaseOrderItem} = require '@/models/purchase_orders'
{Bottle} = require '@/models/bottles'
{prop-with-watch} = require '@/utils'
{object_to_query_string} = require '@bitstillery/common/lib/utils.ts'
app = require('@/app')

export class AddItem
    (vnode) ->
        @product = window.prop new Product
        @purchase_order_item = window.prop new PurchaseOrderItem
        @product_name = window.prop ''
        @splis = window.prop []
        @purchase_order = vnode.attrs.purchase_order
        @hide_from_pricelist_for_countries_selected = window.prop []
        @hide_from_pricelist_for_suppliers_selected = window.prop []
        @init!

        @show_all_splis = window.prop false

        @loading = window.prop false
        @error_message = window.prop ''

        @bottle_gtin_found = window.prop false

        @debounced_get_bottle_prices = debounce 250, @get_bottle_prices_and_pallet_layout
        @product_management_api = new ProductManagementApi()
        @search_bar_controller = null
        @on_added_to_order = vnode.attrs.on_added_to_order
        @is_loading_bottles = false

    init: ~>
        @purchase_order_item!purchase_order = @purchase_order
        @purchase_order_item!customs_status 'T2'
        @purchase_order_item!was_bought_for ''
        @purchase_order_item!suggested_price_per_case null
        @purchase_order_item!number_of_bottles_per_case ''
        @purchase_order_item!number_of_cases ''
        @purchase_order_item!gift_box_type ''
        @purchase_order_item!remark ''
        @purchase_order_item!tax_label ''
        @purchase_order_item!cases_per_pallet ''
        @purchase_order_item!cases_per_pallet_layer ''
        @purchase_order_item!country_of_origin ''
        @purchase_order_item!bottle_gtin_code ''
        @purchase_order_item!was_bought_for null
        @purchase_order_item!bottle_artkey null
        @hide_from_pricelist_for_countries_selected []
        @hide_from_pricelist_for_suppliers_selected []


    after_update_product: (selected_product) ~>
        if !selected_product
            @product!artkey ""
            @product!name ""
            @product!bottles []
        else
            @product_name selected_product.name
            @product!artkey selected_product.artkey
            @product!name selected_product.name
            @product!default_country_code selected_product.default_country_code

            @is_loading_bottles = true
            @product_management_api.get_bottles_for_product(selected_product.artkey).subscribe({
                next: (response) ~>
                    @is_loading_bottles = false
                    @product!bottles response.map((bottle) ->
                        new Bottle(bottle.artkey, bottle.volume, bottle.alcohol_percentage, bottle.refill_status)
                    )
                    m.redraw!
                error: ~>
                    @is_loading_bottles = false
                    m.redraw!
            })


            @purchase_order_item!country_of_origin @product!default_country_code!

        @splis []

    set_bottle: (bottle_artkey) ~>>
        if bottle_artkey
            @purchase_order_item!bottle_artkey bottle_artkey
            await @get_bottle_prices!

    get_bottle_prices_and_pallet_layout: ~>>
        @get_bottle_prices!
        @get_pallet_layout!

    get_bottle_prices: ~>>
        if @purchase_order_item!bottle_artkey!
            const {result} = await app.api.get(
                "discover/bottle/#{@purchase_order_item!bottle_artkey!}/supplier-price-list-items"
            )
            @splis result
            m.redraw!

    get_pallet_layout: ~>>
        if !@purchase_order_item!bottle_artkey! or !@purchase_order_item!number_of_bottles_per_case!
            return

        request = {
            bottle_artkey: @purchase_order_item!bottle_artkey!
            number_of_bottles: @purchase_order_item!number_of_bottles_per_case!
            gift_box_type: @purchase_order_item!gift_box_type!
            customs_status: @purchase_order_item!customs_status!
        }
        request_string = object_to_query_string(request)
        const {result} = await app.api.get('discover/cases/find-pallet-layout?'+request_string)

        @purchase_order_item!cases_per_pallet result.cases_per_pallet
        @purchase_order_item!cases_per_pallet_layer result.cases_per_pallet_layer


    look_up_specs_on_bottle_gtin: ~>
        @error_message ''

        if @purchase_order_item!bottle_gtin_code! == '' or @purchase_order_item!bottle_gtin_code! == null
            @error_message 'Please fill in a bottle gtin before the look up.'
            return

        invalid_message = validate_bottle_gtin(@purchase_order_item!bottle_gtin_code!)
        if invalid_message
            @error_message invalid_message
        else
            data = {bottle_gtin: @purchase_order_item!bottle_gtin_code!}
            api.call 'matcher.look_up_specs_at_bottle_gtin', data, @handle_bottle_gtin_specs_look_up

    handle_bottle_gtin_specs_look_up: (resp) ~>
        if 'message' of resp
            @error_message resp.message
        else
            @bottle_gtin_found true
            # Create a product like response to work with
            bottle = do
                artkey: resp.result[5]
                alcohol_percentage: resp.result[3]
                volume: resp.result[2]
                refill_status: resp.result[4]
            category = do
                name: resp.result[1]
            prod = do
                name: resp.result[0]
                bottles: [ bottle ]
                product_category: category

            @after_update_product prod

    add_to_order: ~>
        @loading true
        @error_message ''

        data = do
            product_name: @product!name!
            purchase_order_artkey: @purchase_order_item!purchase_order!artkey!
            bottle_artkey: @purchase_order_item!bottle_artkey!
            was_bought_for: @purchase_order_item!was_bought_for!
            suggested_price_per_case: @purchase_order_item!suggested_price_per_case!
            refers_to_artkey: @purchase_order_item!refers_to_artkey!
            number_of_bottles_per_case: @purchase_order_item!number_of_bottles_per_case!
            tax_label: @purchase_order_item!tax_label!
            gift_box_type: @purchase_order_item!gift_box_type!
            customs_status: @purchase_order_item!customs_status!
            availability_date: @purchase_order_item!availability_date!
            number_of_cases: @purchase_order_item!number_of_cases!
            cases_per_pallet: @purchase_order_item!cases_per_pallet!
            cases_per_pallet_layer: @purchase_order_item!cases_per_pallet_layer!
            remark: @purchase_order_item!remark!
            bottle_gtin_code: @purchase_order_item!bottle_gtin_code!
            case_gtin_code: @purchase_order_item!case_gtin_code!
            country_of_origin: @purchase_order_item!country_of_origin!
            hide_from_pricelist_for_suppliers: @hide_from_pricelist_for_suppliers_selected!map((artkey) -> +artkey)
            hide_from_pricelist_for_countries: @hide_from_pricelist_for_countries_selected!
        api.call-and-then 'purchase.purchase_order_item.add_item_to_purchase_order' data, do
            success: (resp) ~>
                app.$m.common.observable.broadcast 'purchase_order_updated_'+@purchase_order!artkey!, 'Go fetch'
                @init!
                @splis []
                @product new Product
                @product_name ''
                @search_bar_controller.set_and_submit_search_text ''
                if @on_added_to_order
                    @on_added_to_order!
                app.notifier.notify 'The item was added successfully.', 'success'
            final: (resp) ~>
                @loading false

    view: -> m '.panel.panel-default',
        m '.panel-body.fieldset.largest',
            m '.field-group',
                m AutocompleteInput,
                    label: 'Product'
                    placeholder: "Select a product"
                    default_search_text: @product_name!
                    on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_products(filter_text)
                    suggestion_as_label: (simple_product) -> simple_product.name
                    on_selected: (simple_product) ~> @after_update_product(simple_product)
                    search_bar_controller: (sbc) ~> @search_bar_controller = sbc

            m '.field-group',
                if @is_loading_bottles
                    m Spinner
                else
                    inputs.specs @product!bottles, @purchase_order_item!bottle, {
                        after_update: @set_bottle,
                        label: 'Specs'
                    }

                inputs.number @purchase_order_item!number_of_bottles_per_case, {
                    label: 'Bottles per case',
                    min: 1,
                    required: true,
                    after_update: @debounced_get_bottle_prices
                }

                inputs.number @purchase_order_item!number_of_cases, {
                    label: 'Cases',
                    min: 1,
                    required: true
                }

                m MoneyInput, {
                    label: 'Price per case'
                    value: @purchase_order_item!was_bought_for!
                    on_value: (price) ~>
                        @purchase_order_item!was_bought_for price
                    currency: @purchase_order!was_bought_in!
                    required: true
                }

                m MoneyInput, {
                    label: 'Suggested sales price'
                    value: @purchase_order_item!suggested_price_per_case!
                    on_value: (price) ~>
                        @purchase_order_item!suggested_price_per_case price
                    currency: @purchase_order!was_bought_in!
                    required: true
                }

                inputs.gift_box_type @purchase_order_item!gift_box_type, {
                    after_update: @debounced_get_bottle_prices,
                    label: 'Giftbox'
                }

            m '.field-group',
                m '.field',
                    m 'label' 'Bottle GTIN'
                    m '.control',
                        m 'input', do
                            value: @purchase_order_item!bottle_gtin_code!
                            oninput: (ev) ~> (
                                if ev.target.value == ""
                                    @purchase_order_item!bottle_gtin_code null
                                else
                                    @purchase_order_item!bottle_gtin_code ev.target.value
                                @bottle_gtin_found false
                            )
                        icon-button 'search', do
                            class: 'btn-default'
                            onclick: @look_up_specs_on_bottle_gtin
                            disabled: !@purchase_order_item!bottle_gtin_code!

                inputs.text @purchase_order_item!case_gtin_code, {
                    label: 'Case GTIN'
                }

                inputs.customs_status @purchase_order_item!customs_status, {
                    label: 'Customs status'
                }

                inputs.tax_label @purchase_order_item!tax_label, {
                    label: 'Tax Label',
                }

                m CountriesSelect, {
                    label: 'Country of origin',
                    required: true,
                    selected: @purchase_order_item!country_of_origin!,
                    onchange: (value) ~> @purchase_order_item!country_of_origin(value)
                }

                inputs.text @purchase_order_item!remark, {
                    label: 'Remark'
                }

            m '.field-group.no-fill.flex-end',
                inputs.number @purchase_order_item!cases_per_pallet, {
                    label: 'Cases per Pallet',
                    min: 1
                }

                inputs.number @purchase_order_item!cases_per_pallet_layer, {
                    label: 'Cases per Pallet Layer',
                    min: 1
                }

                m CountriesMultiSelect, do
                    help: 'Hide product in relation pricelists'
                    label: 'Hide in countries'
                    selected_country_codes: @hide_from_pricelist_for_countries_selected!
                    onchange: (value) ~> @hide_from_pricelist_for_countries_selected value

                m RelationMultiSelectDropDown, {
                    help: 'Hide product in relation pricelists'
                    label: 'Hide from relations'
                    selected_relation_artkeys: @hide_from_pricelist_for_suppliers_selected!
                    onchange: (value) ~>
                        @hide_from_pricelist_for_suppliers_selected value
                    get_all_for_drop_down_response$: RelationDropDownData.relations()
                }

                button-with-icon 'Add Product', 'plus', do
                    class: 'btn-primary'
                    onclick: @add_to_order

            if @splis!length > 0 then [
                m '.splis',
                    m 'table.table.table-condensed.table-hover',
                        table-base.colgroup [
                            {name: 'Button', width: 5}
                            {name: 'Supplier', width: 35}
                            {name: 'GB', width: 15}
                            {name: 'Btl / cs', width: 15}
                            {name: 'Price per bottle', width: 15}
                            {name: 'Price per case', width: 15}
                            {name: 'Remark', width: 25}
                        ] |> table-base.auto-scale-columns

                        m 'thead.thead-default' m 'tr',
                            m 'th' m 'span.pull-left' if @purchase_order_item!bottle!?
                                then discover_link.link_from_bottle(@purchase_order_item!bottle!)
                            m 'th' 'Supplier'
                            m 'th' 'GB'
                            m 'th.number' 'Btl / cs'
                            m 'th.price' 'Price per bottle'
                            m 'th.price' 'Price per case'
                            m 'th' 'Remark'
                        m 'tbody',
                            @splis!map((spli, index) ~>
                                row_classes = [
                                    app.$m.data.company_type_class(spli.supplier_company_type),
                                    if spli.supplier_artkey == @purchase_order!supplier!artkey!
                                    then 'is-current-supplier'
                                ].join(' ')
                                m 'tr' {class: row_classes, key: spli.artkey},
                                    m 'td',
                                        m m.route.Link,do
                                            href: pricelist_link.from_bottle_json spli.supplier_price_list_artkey, {
                                                product: {
                                                    name: spli.product_name
                                                },
                                                volume: spli.volume,
                                                alcohol_percentage: spli.alcohol_percentage
                                            }
                                            class: 'pull-left'
                                        , m 'span.glyphicon.glyphicon-new-window'

                                    m 'td' spli.supplier_name
                                    m 'td' spli.gift_box_type
                                    m 'td.number' spli.number_of_bottles_per_case
                                    m 'td.price',
                                        m Amount, do
                                            amount: spli.price_per_bottle
                                            currency: spli.currency
                                            display_currency: app.$s.currencies.default
                                    m 'td.price',
                                        m Amount, do
                                            amount: spli.price_per_case
                                            currency: spli.currency
                                            display_currency: app.$s.currencies.default
                                    m 'td' spli.aux_info
                                )
                if @splis!length > 2
                    m '' {onclick: @show_all_splis.bind(@, !@show_all_splis!)},
                        if @show_all_splis!
                            m 'span.glyphicon.glyphicon-chevron-up'
                        else
                            m 'span.glyphicon.glyphicon-chevron-down'
            ]

            if @error_message!
                m '.alert.alert-danger.alert-dismissible' {role: 'alert'},
                    m 'button.close' do
                        type: 'button'
                        'data-dismiss': 'alert'
                        'aria-label': 'Close',
                        onclick: @error_message.bind @error_msg, ''
                    , m 'span' '×'
                    m 'span' @error_message!
