import m from 'mithril'
import {merge_deep} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Button,
    ButtonGroup,
    FieldCheckbox,
    FieldSelect,
    FieldUpload,
    FieldText,
    FieldTextArea,
    Icon,
    Stepper,
} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {api, logger} from '@bitstillery/common/app'
import {invalid_fields, invalid_fields_format} from '@bitstillery/common/lib/validation'
import {notifier} from '@bitstillery/common/app'
import {watch} from '@bitstillery/common/lib/store'
import {conditional, required, validation} from '@bitstillery/common/lib/validation'
import {datetime_to_date} from '@bitstillery/common/lib/utils'

import {collection as collection_pricelists} from '../list_pricelists'

import {ColumnsPicker} from './columns_picker'

import {$m, $s} from '@/app'

export const context = {
    data: proxy({
        always_active: false,
        cache_key: '', // caches api calls that return the Excel rows from Swift
        candidates: {}, // resolve results in preview
        candidates_loaded: false, // blocks resolving when the candidates failed to load or are still loading
        column_mapping: {}, // the configuration of the column mapping
        column_render: [], // dynamically generated CollectionItems columns from config headers
        columns: [], // the type of column to choice from
        config_loaded: false, // SetupColumns depends on the config to be loaded
        default_currency: '',
        default_customs_status: '',
        default_incoterm: 'EXW',
        default_incoterm_location: '',
        description: '',
        end_date: '',
        file_name: '',
        file: {
            content_type: '',
            data: '',
            name: '',
        },
        header: [],
        incoterm_location_options: [],
        relation_artkey: null,
        supplier_name: '',
        started_processing_on: null, // this indicates that an import has already been started; from then on, no other import can be made
        status: '',
        start_date: new Date().toLocaleDateString('en-CA'),
        store_cpp_on_case: false,
        store_cpl_on_case: false,
    }),
    data_transform: async(context_data, raw_data) => {
        if (!raw_data) return

        context_data.relation_artkey = raw_data.supplier.artkey
        context_data.supplier_name = raw_data.supplier.name
        context_data.end_date = datetime_to_date(context_data.end_date)
        context_data.start_date = datetime_to_date(context_data.start_date)
        context_data.file.name = raw_data.file_name
        // Before the pricelist is processed, its status will be 'waiting_for_analyze',
        // not 'always_active'. Instead of checking the pricelist status for 'always_active',
        // we check for a lacking end_date to determine always_active.
        context_data.always_active = !raw_data.end_date
    },
    entity: 'Pricelist',
    name: 'workflow_pricelist',
    routes: {
        context: () => `discover/supplier-price-lists/${$s.context.id}`,
        base: '/market/pricelists/upsert',
    },
    stepper: proxy({
        _direction: () => $s.panels.context.collapsed ? 'vertical' : 'horizontal',
        loading: true,
        options: [
            {
                _disabled: false,
                icon: 'edit',
                link: () => `#!${context.model_to_route({step: 0})}`,
                title: 'Fill Pricelist details',
            },
            {
                _disabled:() => {
                    if (context.data.started_processing_on) {
                        return true
                    }

                    const validation_fields = validation_steps[0]
                    const invalid_fields = validation_fields.map((i) => context.$v[i]).filter((i) => i && i._invalid)
                    return invalid_fields.length
                },
                link: () => `#!${context.model_to_route({step: 1})}`,
                icon: 'excel',
                title: 'Import from Excel',
            },
            {
                _disabled:() => {
                    if (context.data.started_processing_on) {
                        return true
                    }
                    const validation_fields = validation_steps[1]
                    const invalid_fields = validation_fields.map((i) => context.$v[i]).filter((i) => i && i._invalid)
                    return invalid_fields.length
                },
                link: () => `#!${context.model_to_route({step: 2})}`,
                icon: 'auto_fix',
                title: 'Adjust Column Setup',
            },
        ],
        selection: 0,
    }),
}

window.context = context

context.$v = {
    default_currency: validation([context.data, 'default_currency'], required()),
    end_date: validation([context.data, 'end_date'], conditional(() => !context.data.always_active, required())),
    file: validation([context.data.file, 'name'], required()),
    default_incoterm: validation([context.data, 'default_incoterm'], required()),
    default_incoterm_location: validation([context.data, 'default_incoterm_location'], required()),
    start_date: validation([context.data, 'start_date'], required()),
    relation_artkey: validation([context.data, 'relation_artkey'], required()),
}

// Dynamically generated columns for the candidates Collection & ColumnsPicker
export const columns_preview: any[] = []

export const mappings = {
    cpl: ['Cases per pallet layer', 'Bottles per pallet layer'],
    cpp: ['Cases per pallet', 'Bottles per pallet'],
    product: ['Magic product finder', 'Product', 'Product with alcohol %', 'Product with alcohol % and size'],
}

/**
 * Determines whether a type of column is selected, by grouping column variations together.
 */
export function is_mapped(column_type) {
    return mappings[column_type].some((column) => Object.values(context.data.column_mapping).includes(column))
}

export const validation_steps = {
    0: ['default_currency', 'end_date', 'default_incoterm', 'default_incoterm_location', 'start_date', 'relation_artkey'],
    1: ['default_currency', 'end_date', 'default_incoterm', 'default_incoterm_location', 'start_date', 'relation_artkey', 'file'],
}

export class WorkflowPricelist extends MithrilTsxComponent<any> {

    watchers: any[] = []

    async oninit() {
        this.watchers.push(watch(context.stepper, 'selection', async(new_step) => {
            if (new_step >= 1 && !context.data.config_loaded && $s.context.data.file_name) {
                this.load_pricelist_config()
            }
        }))
    }

    onremove() {
        this.watchers.forEach((unwatch:any) => unwatch())
    }

    async load_pricelist_config() {
        const {result: config} = await api.get(`discover/supplier-price-lists/${$s.context.id}/config?cache_key=${context.data.cache_key}`) as any
        const {result: column_types} = await api.get('discover/supplier-price-lists/columns') as any

        merge_deep(context.data, {
            cache_key: config.cache_key, // sets Swift/Excel-related api call cache
            column_mapping: config.column_mapping,
            columns: [...column_types.columns, ...column_types.other_columns].sort((a, b) => a.localeCompare(b)),
            header: config.header,
        })

        this.render_columns()
        context.data.config_loaded = true
        logger.info(`[pricelist] pricelist config loaded (cache: ${config.cache_key}`)
    }

    async process_pricelist() {
        logger.info('[pricelist] process pricelist')
        const {result, success} = await api.post(`discover/supplier-price-lists/${$s.context.id}/process`, {
            column_mapping: context.data.column_mapping,
            header: context.data.header,
            store_cpp: context.data.store_cpp_on_case,
            store_cpl: context.data.store_cpl_on_case,
        }, true)

        if (success) {
            notifier.notify('Processing pricelist! Refresh the page to see updates.', 'info')
            m.route.set(`/market/pricelists/${$s.context.id}?meta=true&tabId=resolved`)
        } else {
            notifier.notify(`Something went wrong. Please notify IT support about this: ${result}`, 'danger')
        }
    }

    render_columns() {
        const columns = [...Object.entries(context.data.header)
            .map(([column_index, column_name]) => {
                // Per-cell status, so we can highlight an entire column.
                return {
                    name: column_name,
                    render: (row) => {
                        return row.candidate[column_index]
                    },
                    type: () => {
                        if (!(column_index in context.data.column_mapping)) return 'hidden'
                        if (context.data.column_mapping[column_index] === '') return 'danger'
                        return 'default'
                    },
                }
            }), {
            className: 'cell-status',
            name: '',
            render: () => null,
            width: 'var(--coll-status-width)',
        }] as any

        columns_preview.splice(0, columns_preview.length, ...columns)
    }

    async upsert_pricelist(with_file = false, next_step = false) {
        const data = {
            always_active: context.data.always_active,
            default_currency: context.data.default_currency,
            default_customs_status: context.data.default_customs_status,
            default_incoterm: context.data.default_incoterm,
            default_incoterm_location: context.data.default_incoterm_location,
            description: context.data.description,
            relation_artkey: context.data.relation_artkey,
            start_date: context.data.start_date,
            end_date: context.data.end_date ? context.data.end_date : null,
        }

        if (with_file) {
            // A new file is set when the data is filled; otherwise
            // when only the name is set, the current file is reused.
            if (context.data.file.data) {
                data.file_holder = {
                    file_contents: context.data.file.data,
                    file_name: context.data.file.name,
                }
            }
        }

        if ($s.context.id) {
            await api.put(`discover/supplier-price-lists/${$s.context.id}`, data)
            logger.info(`[workflow_pricelist] updated pricelist ${$s.context.id}`)
        } else {
            const {result: result_upload} = await api.post('discover/supplier-price-lists', data, true) as any
            merge_deep($s.context, {id: result_upload.artkey})
            logger.info(`[workflow_pricelist] created new pricelist (${$s.context.id})`)
        }

        if (with_file) {
            await this.load_pricelist_config()
        }

        // Refresh the collection to show a newly created pricelist.
        collection_pricelists.reset_query()

        // Update the context, so the context state reflects an updated file.
        if (with_file) {
            $s.context.key = Date.now()
        }
        if (next_step) {
            context.stepper.selection += 1
        }
    }

    view() {
        return <div className="c-workflow-pricelist">
            <div className="workflow">
                <Stepper
                    model={context.stepper}
                    options={context.stepper.options}
                    tipPlacement="right"
                />
            </div>
            <div className="steps">
                {context.stepper.selection === 0 && <div className="form">
                    <FieldText
                        disabled={$s.context.id}
                        help="The supplier of this pricelist"
                        label="Supplier"
                        ref={[context.data, 'supplier_name']}
                        submit={async(e, suggestion) => {
                            if (suggestion) {
                                // Use legacy supplier endpoint to get all th supplier fields.
                                const {result: supplier} = await api.post('suppliers.get_supplier', {artkey: suggestion.artkey}) as any
                                Object.assign(context.data, {
                                    default_currency: supplier.currency,
                                    default_incoterm: supplier.incoterm,
                                    default_customs_status: supplier.customs_status,
                                    relation_artkey: suggestion.artkey,
                                })

                                const {result: destinations} = await api.get(`discover/relations/${context.data.relation_artkey}/destinations`) as any
                                const sorted_destinations = destinations.sort((a, b) => {
                                    if (a.is_primary) return -1
                                    if (b.is_primary) return 1
                                }).map((i) => {
                                    return [`${i.city} - ${i.country_code}`, `${i.city} - ${i.country_code}`]
                                })

                                context.data.incoterm_location_options.splice(0, context.data.incoterm_location_options.length, ...sorted_destinations)
                                // Try to use the primary destination first; otherwise use the supplier city & country.
                                const destination_primary = destinations.find((i) => i.is_primary)
                                if (destination_primary) {
                                    context.data.default_incoterm_location = `${destination_primary.city} - ${destination_primary.country_code}`
                                } else {
                                    context.data.default_incoterm_location = `${supplier.city.trim()} - ${supplier.country_code}`
                                }
                            } else {
                                context.data.relation_artkey = null
                            }
                        }}
                        suggestions={async(filter_text) => {
                            const {result} = await api.get(`discover/relations?search_terms=${filter_text}&only_suppliers=true`) as any
                            return result
                        }}
                        validation={context.$v.relation_artkey}
                    />

                    <FieldCheckbox
                        help="If this is checked, the pricelist will always be active, even if the start or end date is in the past."
                        label="Always active"
                        onAfterChange={() => {
                            if (context.data.always_active) {
                                context.data.end_date = null
                            }
                        }}
                        ref={[context.data, 'always_active']}
                    />

                    <div className="field-group">
                        <FieldText
                            help="From when should this pricelist be active?"
                            label="Start date"
                            ref={[context.data, 'start_date']}
                            type="date"
                            validation={context.$v.start_date}
                        />

                        <FieldText
                            disabled={context.data.always_active}
                            help="Until when should this pricelist be active?"
                            label="End date"
                            ref={[context.data, 'end_date']}
                            type="date"
                            validation={context.$v.end_date}
                        />
                    </div>

                    <div className="field-group">
                        <FieldSelect
                            help="Fallback incoterm"
                            label="Default incoterm"
                            ref={[context.data, 'default_incoterm']}
                            options={$m.data.incoterms}
                            validation={context.$v.default_incoterm}
                        />

                        {context.data.incoterm_location_options.length ? <FieldSelect
                            label="Default location"
                            options={context.data.incoterm_location_options}
                            ref= {[context.data, 'default_incoterm_location']}
                        /> : <FieldText
                            help="Fallback incoterm location"
                            label="Default location"
                            ref={[context.data, 'default_incoterm_location']}
                            validation={context.$v.default_incoterm_location}
                        />}

                    </div>

                    <div className="field-group">
                        <FieldSelect
                            help="The default customs status is a fallback for when no customs status can be found in the price list."
                            label="Default customs status"
                            ref={[context.data, 'default_customs_status']}
                            options={$m.data.customs_status.map((i) => [i, i])}
                        />

                        <FieldSelect
                            label="Currency"
                            ref={[context.data, 'default_currency']}
                            options={$s.currencies.all.map((i) => [i, i])}
                            validation={context.$v.default_currency}
                        />
                    </div>

                    <FieldTextArea
                        help="The pricelist description is used to identify the pricelist in the system."
                        label="Description"
                        ref={[context.data, 'description']}
                    />
                    <ButtonGroup className="pt-3" type="panel">
                        <Button
                            disabled={(() => {
                                const validation_fields = validation_steps[context.stepper.selection]
                                const invalid_fields = validation_fields.map((i) => context.$v[i]).filter((i) => i && i._invalid)
                                return invalid_fields.length
                            })()}
                            icon="save"
                            text="Save & Close"
                            tip={() => {
                                const validation_fields = validation_steps[context.stepper.selection]
                                const fields = invalid_fields(context.$v).filter((i) => validation_fields.includes(i[0]))
                                return invalid_fields_format(fields, 'tip')
                            }}
                            onclick={async() => {
                                await this.upsert_pricelist(false, true)
                                merge_deep($s.context, {id: null, name: null})
                            }}
                            type="default"
                        />

                        <Button
                            disabled={(() => {
                                const validation_fields = validation_steps[context.stepper.selection]
                                const invalid_fields = validation_fields.map((i) => context.$v[i]).filter((i) => i && i._invalid)
                                return invalid_fields.length
                            })()}
                            icon="arrow_right_cicle_outline"
                            text={(() => {
                                if (!context.data.started_processing_on) {
                                    return 'Save & Import Excel'
                                }
                                return 'Manage Products'
                            })()}
                            tip={() => {
                                const validation_fields = validation_steps[context.stepper.selection]
                                const fields = invalid_fields(context.$v).filter((i) => validation_fields.includes(i[0]))
                                return invalid_fields_format(fields, 'tip')
                            }}
                            type={!context.data.started_processing_on ? 'success' : 'info'}
                            onclick={() => {
                                // We already have a file; and the pricelist status doesn't allow us to
                                // set a new one. Instead, we redirect directly to the SPLI/Sourceline view.
                                if (!context.data.started_processing_on) {
                                    this.upsert_pricelist(false, true)
                                } else {
                                    m.route.set(`/market/pricelists/${$s.context.id}?meta=true&tabId=resolved`)
                                }
                            }}
                        />
                    </ButtonGroup>
                </div>}

                {context.stepper.selection === 1 && <div className="form">
                    <FieldUpload
                        accept="application/xml,.xls,.xlsx"
                        help="Upload an Excel pricelist file"
                        label="Pricelist Excel"
                        model={context.data.file}
                        placeholder={'Ctrl-v or drag your pricelist here'}
                        type="file"
                        validation={context.$v.file}
                    />

                    <ButtonGroup className="pt-3" type="panel">
                        <Button
                            disabled={(() => {
                                const validation_fields = validation_steps[context.stepper.selection]
                                const invalid_fields = validation_fields.map((i) => context.$v[i]).filter((i) => i && i._invalid)
                                return invalid_fields.length
                            })()}
                            icon="arrow_right_cicle_outline"
                            text="Save & Adjust Columns"
                            tip={() => {
                                const validation_fields = validation_steps[context.stepper.selection]
                                const fields = invalid_fields(context.$v).filter((i) => validation_fields.includes(i[0]))
                                return invalid_fields_format(fields, 'tip')
                            }}
                            onclick={async() => {
                                await this.upsert_pricelist(true, true)
                            }}
                            type="success"
                        />
                    </ButtonGroup>
                </div>}

                {context.stepper.selection === 2 && (() => {
                    const product_mapped = is_mapped('product')
                    return <div className="form">
                        <ColumnsPicker type="list" />
                        <div className="setup-stats">
                            <div className="checklist">
                                <div className="check">
                                    {(() => {
                                        return [
                                            <Icon
                                                name={product_mapped ? 'checked' : 'warning'}
                                                tip="Product column selected"
                                                type={product_mapped ? 'success' : 'default'}
                                            />,
                                            <span>Product column selected</span>,
                                        ]
                                    })()}
                                </div>
                            </div>

                            {(is_mapped('cpp') || is_mapped('cpl')) && <div className="modifiers">
                                {is_mapped('cpp') && <FieldCheckbox
                                    label="Override CPP"
                                    help="Save cases per pallet on the matched product."
                                    ref={[context.data, 'store_cpp_on_case']}
                                />}

                                {is_mapped('cpl') && <FieldCheckbox
                                    label="Override CPL"
                                    help="Save cases per pallet layer on the matched product."
                                    ref={[context.data, 'store_cpl_on_case']}
                                />}

                                {(context.data.store_cpp_on_case || context.data.store_cpl_on_case) && (
                                    <div className="alert alert-danger">
                                        <div className="header"><Icon name="warning" type="danger"/>This action cannot be undone</div>
                                        <div className="text">
                                This will overwrite the Product Case CPP/CPL values in Discover with the values from this pricelist.
                                These values are used throughout Discover and the Portal. Do NOT use this option unless you are sure,
                                what it means.
                                        </div>
                                    </div>
                                )}
                            </div>}
                        </div>

                        <ButtonGroup className="pt-3" type="panel">
                            <Button
                                disabled={
                                    !product_mapped || !context.data.candidates_loaded ||
                                    Object.values(context.data.column_mapping).some((i) => i === '')
                                }
                                onclick={() => {
                                    this.process_pricelist()
                                }}
                                icon="cog"
                                text="Import Products"
                                type="success"
                            />
                        </ButtonGroup>
                    </div>
                })()}
            </div>
        </div>
    }
}
