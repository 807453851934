{
    "attachments": {
        "actions": {
            "category_cancel": "Cancel",
            "category_confirm": "Confirm category",
            "download_all": "Download all"
        },
        "tips": {
            "delete_attachment": "Deletes attachment without confirmation(!)",
            "edit_category": "Modify category and description of this attachment"
        }
    },
    "bar_categories": {
        "title": "Category Options"
    },
    "crm": {
        "text": "CRM",
        "tip": "Customer Relation Management"
    },
    "crm_bounced-email": {},
    "crm_bounced_email": {},
    "crm_email": {
        "text": "Email",
        "tip": "Compose and send emails to groups of customers"
    },
    "crm_email_bounced": {},
    "crm_email_bounces": {},
    "crm_leads": {
        "text": "Leads",
        "tip": "Warm leads are coming from portal registration requests; cold leads are uploaded from excel"
    },
    "crm_relations": {
        "text": "Relations",
        "tip": "Customer Relation Management"
    },
    "crm_uploads": {
        "text": "Leads Import",
        "tip": "Import cold leads from excel"
    },
    "dashboard": {
        "text": "Analytics",
        "tip": "Business Intelligence for management"
    },
    "dashboard_diana": {
        "text": "DIANA",
        "tip": "Discover Analytics"
    },
    "dashboard_figures": {
        "text": "Figures Dashboard",
        "tip": "Generic Management Indicators"
    },
    "dashboard_mine": {
        "text": "Analytics",
        "tip": "Business Intelligence for management"
    },
    "dashboard_portal": {
        "text": "Portal Analytics",
        "tip": "Visitors & general order stats from the Portal"
    },
    "dashboard_reports": {
        "text": "Reports",
        "tip": "Several Business Intelligence Reports"
    },
    "dashboard_search_log": {
        "text": "Search Queries",
        "tip": "See which products people are searching for in the portal"
    },
    "data": {
        "text": "Configuration",
        "tip": "Manage Discover"
    },
    "data_afvalfonds": {
        "text": "Afvalfonds"
    },
    "data_attachment_categories": {
        "text": "Attachment Categories"
    },
    "data_brand_holders": {
        "text": "Brand Holders"
    },
    "data_brands": {
        "text": "Brands"
    },
    "data_cbs": {
        "text": "CBS Export"
    },
    "data_commenttemplates": {
        "text": "Comment Templates",
        "tip": "Templates to use in comments"
    },
    "data_config": {},
    "data_email_placeholders": {
        "text": "Email Templates"
    },
    "data_excise_categories": {
        "text": "Excise"
    },
    "data_gitlab": {
        "text": "Support"
    },
    "data_insurancetypes": {
        "text": "Insurances"
    },
    "data_item_tags": {
        "text": "Product Tags"
    },
    "data_ledgers": {
        "text": "Ledgers"
    },
    "data_loendersloot_messages": {
        "text": "Loendersloot Messages"
    },
    "data_loendersloot_tags": {
        "text": "Loendersloot Tags"
    },
    "data_margin_thresholds": {
        "text": "Margin Thresholds"
    },
    "data_paymentterms": {
        "text": "Payment terms"
    },
    "data_product_categories": {
        "text": "Product Categories"
    },
    "data_products": {
        "text": "Products",
        "tip": "Manage Products"
    },
    "data_settings": {},
    "data_system_parameters": {
        "text": "Parameters"
    },
    "data_user_manage": {
        "profile": {
            "label": {
                "gitlab": "Gitlab username"
            }
        },
        "text": "Users"
    },
    "data_vatcheck": {
        "text": "VAT Check"
    },
    "data_vatrates": {
        "text": "VAT Rates"
    },
    "data_vattypes": {
        "text": "VAT Types"
    },
    "data_warehouses": {
        "text": "Warehouses",
        "tip": "Destinations for products"
    },
    "filters": {
        "sort": {
            "types": {
                "artkey": "Date of entry",
                "code": "Code",
                "end_date": "End date",
                "last_event_on": "Event date",
                "location": "Location",
                "matching_score": "Matching score",
                "max_stock_age": "Maximum Stock age",
                "name": "Name",
                "number_of_bottles": "Number of bottles",
                "number_of_events": "Event count",
                "price_list_manager_name": "Pricelist manager",
                "price_per_bottle": "Price per bottle",
                "relation_name": "Relation",
                "sales_manager": "Sales manager",
                "score": "Score",
                "start_date": "Start date",
                "supplier_name": "Supplier name",
                "supplier_price_list_description": "Pricelist description"
            }
        },
        "title": {
            "bottle_alcohol_percentage_range": "Alcohol percentage",
            "bottle_volume_range": "Bottle volume",
            "buyers": "Buyers",
            "categories": "Categories",
            "category_artkeys": "Categories",
            "client_status": "Client status",
            "company_type": "Company type",
            "event_type": "Event type",
            "manager_artkey": "Sales or Purchase manager",
            "only_without_stock": "Only without stock",
            "operates_online": "Operates online",
            "pricelist_status": "Pricelist status",
            "purchase_manager": "Purchase manager",
            "refill_status": "Refill status",
            "sales_manager": "Sales manager",
            "search": "Search terms",
            "search_terms": "Search terms",
            "show_mode": "Include / Exclude",
            "state": "Pricelist status",
            "stock_available_range": "Cases available in stock",
            "suppliers": "Suppliers"
        }
    },
    "finance": {
        "text": "Finance",
        "tip": "Manage excise, payment terms, ledgers, VAT and more"
    },
    "logistics": {
        "text": "Logistics",
        "tip": "Transport, insurance, warehouse and more"
    },
    "market": {
        "text": "Market",
        "tip": "Find the best and cheapest products in the market"
    },
    "market_best_buys": {
        "text": "Best Buys",
        "tip": "A selection of best performing products from the market"
    },
    "market_discover": {
        "text": "Products",
        "tip": "Find products from suppliers"
    },
    "market_explore": {
        "text": "Explorer",
        "tip": "Historical purchase, sales, stock & pricelist information about how a product has been performing in the past"
    },
    "market_pricelists": {
        "text": "Pricelists",
        "tip": "Map and match supplier pricelists to products"
    },
    "market_resolve": {},
    "market_statistics": {
        "text": "Statistics",
        "tip": "Purchase & Sales value between relation/product and relation/trader"
    },
    "misc": {
        "text": "Miscellaneous",
        "tip": "Miscellaneous settings"
    },
    "mutations": {
        "text": "Mutations"
    },
    "my_discover": {
        "text": "My Discover",
        "tip": "Your personal Discover environment"
    },
    "my_discover_colleagues": {
        "basic": "Today",
        "detailed": "Period",
        "text": "Colleagues",
        "tip": "Show the work schedule of your colleagues"
    },
    "my_discover_dashboard": {
        "text": "Inbox",
        "tip": "Notifications & messages"
    },
    "my_discover_downloads": {
        "text": "Downloads",
        "tip": "Downloads you started earlier can be retrieved from here"
    },
    "my_discover_profile": {
        "text": "My Profile",
        "tip": "Change your preferences"
    },
    "notifications": {
        "poi_removed": "Purchase order item {{name}} was removed",
        "saved_offer_items_alt_one": "Saved offeritem (silent)",
        "saved_offer_items_alt_other": "Saved {{count}} offeritems (silent)",
        "saved_offer_items_one": "Saved offeritem",
        "saved_offer_items_other": "Saved {{count}} offeritems",
        "sick_updated": "User status updated to sick",
        "spli_unresolved": "Moved pricelist item {{name}} back to unresolved"
    },
    "offer": {
        "text": "Offers",
        "tip": "Manage discounts & vouchers for customers"
    },
    "offer_offers": {
        "text": "Manage Offers",
        "tip": "Customized prices and product lists for customers"
    },
    "offer_relation_dashboard": {
        "text": "Relation Dashboard",
        "tip": "View offer details for a relation"
    },
    "offer_voucher_promotions": {},
    "offer_vouchers": {
        "text": "Vouchers",
        "tip": "Create special discounts for (groups of) customers"
    },
    "panel": {},
    "panels": {
        "context": {
            "title_data_card": "Data Card",
            "title_edit_add_to_custom_offer": "Add to Custom Offer",
            "title_edit_add_to_order": "Add to Order",
            "title_edit_price": "Edit Price",
            "title_edit_spli": "Edit Supplier Price List Item",
            "title_resolve_line": "Resolve sourceline"
        }
    },
    "pdf": {
        "actions": {
            "download": "Download",
            "refresh": "Refresh"
        }
    },
    "poi": {
        "remove": {
            "button": "Remove POI",
            "dialog": {
                "confirm": "Are you sure you want to remove purchase order item \"{{product_name}} ({{volume}}cl / {{alcohol_percentage}}%)\"?",
                "soi_confirm": "Removing the purchase order item will convert the related sales order items to TBO.",
                "soi_list_one": "This purchase order item is referenced in {{count}} sales order:",
                "soi_list_other": "This purchase order item is referenced in {{count}} sales orders:"
            }
        }
    },
    "portal": {
        "text": "Portal",
        "tip": "Customer Portal related settings"
    },
    "portal_portal_demo_users": {
        "text": "Demo Users",
        "tip": "A way to let customers login to our sales portal to safely look around, without them having the option to order anything"
    },
    "portal_portal_news": {
        "text": "News",
        "tip": "View news items in the portal (unused)"
    },
    "portal_portal_tips": {
        "text": "Tips",
        "tip": "These tips are randomly shown to customers n the portal dashboard"
    },
    "portal_portal_users": {
        "text": "Users",
        "tip": "Manage relation contact persons with a portal account"
    },
    "pricelist": {
        "text": "Sales Prices",
        "tip": "Sales Prices"
    },
    "pricelist_approval": {
        "text": "Approval",
        "tip": "Approve products and their prices from supplier pricelists"
    },
    "pricelist_approval_group": {
        "text": "Approval",
        "tip": "Approve products and their prices from supplier pricelists"
    },
    "pricelist_compare_prices": {
        "text": "Compare",
        "tip": "Compare our prices with the market"
    },
    "pricelist_offer_item_list": {
        "text": "Sales Prices",
        "tip": "Determine base product costs for customers"
    },
    "pricelist_products_of_interest": {
        "text": "Products of Interest",
        "tip": "Create offers for relations with products that might interest them"
    },
    "pricelist_silent_stock": {
        "text": "Approval - Idle Stock",
        "tip": "Products that aren't sold well"
    },
    "pricelist_spotlight": {
        "text": "Spotlight",
        "tip": "Special offer that is shown on the sales portal dashboard"
    },
    "pricelist_work": {
        "text": "Approval - TBO",
        "tip": "Approve products and their prices from supplier pricelists"
    },
    "processes": {
        "manage_offer": {
            "create_email": "Compose Email Message",
            "create_offer": "Create New Offer",
            "custom_products": "Customize Products",
            "edit_email": "Edit Email Message",
            "edit_offer": "Edit Offer",
            "email_recipients": "Select Recipients",
            "email_send": "Mail Customers",
            "list": "Manage Offers",
            "relations": "Select Relations",
            "select_products": "Select Products"
        },
        "new_offer": {},
        "vouchers": {
            "create": "Create Voucher",
            "edit": "Edit Voucher",
            "list": "Manage Vouchers",
            "relations": "Select Relations"
        }
    },
    "products": {
        "text": "Products",
        "tip": "Product-related settings"
    },
    "profile": {
        "actions": {
            "call_sick": "Call in Sick",
            "cancel": "Cancel",
            "exact_login": "Exact Login",
            "exact_logout": "Exact Logout",
            "reset_otp": "Reset OTP"
        },
        "otp": {
            "description": "The One Time Password code is used as an additional security code on login. Are you sure you want to reset this additional security code? A new OTP code can be installed in the authenticator when logging in next time."
        },
        "sick": {
            "description": {
                "other": "When reporting a colleague as sick, the colleague's status will remain \"sick\" everyday, until they manually update it",
                "self": "When calling in sick, your status will remain \"sick\" everyday, until you manually update it"
            }
        },
        "tabs": {
            "details": "Details",
            "schedule": "Schedule",
            "security": "Security"
        }
    },
    "purchase_orders": {
        "text": "Purchasing",
        "tip": "Supplier Purchase Orders & To Be Ordered products"
    },
    "purchase_orders_items_list": {
        "text": "Purchased Products",
        "tip": "An overview of products that have been purchased"
    },
    "purchase_orders_manage": {
        "text": "Purchase Orders",
        "tip": "Manage Supplier Purchase Orders"
    },
    "purchase_orders_tbo": {
        "text": "To Be Ordered",
        "tip": "A list of products requested by sales, but which are not in stock yet. The source of the request could be from the customer in the sales portal, or from a trader managing the sales order"
    },
    "purchase_orders_up_is_up": {
        "text": "Almost out of stock",
        "tip": "Products that are projected to get out of stock soon"
    },
    "sales_orders": {
        "text": "Sales",
        "tip": "Sales Orders, margins & targets"
    },
    "sales_orders_assigned_stock": {
        "text": "Assigned Stock",
        "tip": "Lookup To Be Ordered items per trader"
    },
    "sales_orders_manage": {
        "text": "Sales Orders",
        "tip": "Customer sales orders"
    },
    "sales_orders_margin_approval": {
        "text": "Margin Approval",
        "tip": "Verify margins and approve sales orders"
    },
    "sales_orders_revenue": {
        "text": "Sales Targets",
        "tip": "View revenue per sales order"
    },
    "sales_orders_targets": {
        "text": "Trader Targets",
        "tip": "Determine yearly revenue targets per trader"
    },
    "settings": {
        "text": "Settings",
        "tip": "Manage system settings for Discover"
    },
    "stock": {
        "actions": {
            "bring_in_stock": "Bring items in stock",
            "export_excel": "Export to Excel",
            "item_edit": "Edit item",
            "move_warehouse": "Move to Warehouse",
            "mutation_del": "Delete mutation",
            "mutation_edit": "Edit mutation",
            "outtake_instructions": "Outtake instructions",
            "recalculate_availability": "Recalculate availability",
            "rerun_differences": "Compare stock",
            "to_loe": "Send Loendersloot",
            "to_non_loe": "Mutation to stock",
            "update_bottles_per_case": "Modify bottles / case",
            "update_cases": "Modify cases",
            "update_product": "Modify product"
        },
        "text": "Stock",
        "tip": "Manage Stock",
        "tips": {
            "mutation_actions_disabled": "Only enabled for mutations that are in transit, have a 'move to warehouse' mutation reason and a reference."
        }
    },
    "stock_image_review": {
        "text": "Photo Review",
        "tip": "Review product photos from the warehouse; these photos are used in our sales portal, so please be careful to only use 4:3 (landscape) photos"
    },
    "stock_manage": {
        "text": "Stock",
        "tip": "Manage stock and inventory"
    },
    "stock_mutations_manage": {
        "text": "Manage mutations",
        "tip": "Manage changes in the stock"
    },
    "stock_mutations_manage_move_to_warehouse": {},
    "stock_mutations_move_to_warehouse": {
        "text": "Move to warehouse"
    },
    "stock_recon": {
        "text": "Warehouse Differences",
        "tip": "A list of reported Warehouse issues"
    },
    "table": {
        "fetch_more": "Fetch more items"
    },
    "templates": {
        "text": "Templates",
        "tip": "Manage templates"
    },
    "tips": {
        "spli_unresolve": "Move the pricelist item back to the unresolved list",
        "spli_unresolve_tbo": "This pricelist item has matching TBO items"
    }
}