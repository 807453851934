import {events} from '@bitstillery/common/app'
import {api} from '@bitstillery/common/app'

import _models from './_data.ls'

import {ExactKeys} from '@/exact'
import {RegionDropDownData} from '@/factserver_api/relation_api'

export const {
    AttachmentCategory,
    Brand,
    BrandHolder,
    CommentTemplate,
    ContactPerson,
    ContactPersonDataModel,
    Delivery,
    DestinationsDataModel,
    EmailAttachment,
    EmailPlaceholder,
    EmailPlaceholderTranslation,
    EmailRecipientType,
    InsuranceType,
    InsuranceTypeDataModel,
    ItemTag,
    ItemTagDataModel,
    ItemTagCategory,
    ItemTagCategoryDataModel,
    ItemTagCategoryCardinality,
    ItemTagCategoryConstants,
    Ledger,
    LedgersDataModel,
    LedgerType,
    PaymentTerm,
    PaymentTermDataModel,
    Product,
    ProductCategory,
    TaxLabelConstants,
    VatRate,
    VatRateDataModel,
    VatType,
    VatTypeDataModel,
    Warehouse,
} = _models

export const bottle_refill_statusses = ['ref', 'nonref']
export const collection_states = {}
export const customs_status = ['T1', 'T2']

export const availability_statuses = [
    'Stock',
    '1 week',
    '2 weeks',
    '3 weeks',
    '4 weeks',
    '> 4 weeks',
]

export const client_statuses = [
    'Client',
    'Prospect',
    'Low Potential',
    'Not Active',
    'Do Not Approach',
]

export function company_type_class(status) {
    status = status || ''
    status = status.toLowerCase().replace(' / ', '-').replace('/', '-').replace(' ', '-')
    return `is-${status}`
}

export const company_types = [
    'Competitor',
    'Agent / Importer NL',
    'Agent / Importer',
    'Retail',
    'Wholesale',
    'Trader',
    'Travel Retail / Duty Free',
    'MSI',
    'Logistics / Warehouse',
    'Service partner',
    'Unknown',
]

export const customs_visibility_to_statuses = {
    T1: 'T1',
    T2: 'T2',
    both: 'both',
    hidden: 'both',
    null: 'both',
}

export let exact_keys = {}

// Update list of data products when it is rendered.
export const update_data_products = window.prop(false)

export const gift_box_types = [
    'GB',
    'GB + Glass(es)',
    '+ Glass(es)',
    'GB Bag',
    'Gift Set',
    'Gift Tin',
    'Wooden GB',
    'Cradle',
]

export const humanize_customs_visibility = {
    T1: 'Only T1',
    T2: 'Only T2',
    both: 'Both T1 and T2',
    hidden: 'Hide customs status, show both T1 and T2 items',
    null: 'Nothing configured, show both T1 and T2',
}

export const price_list_frequencies = {
    '1 weeks': 'Weekly',
    '2 weeks': 'Bi-weekly',
    '1 months': 'Monthly',
    '3 months': 'Quarterly',
    '1 years': 'Yearly',
    never: 'Never',
    other: 'Other',
}

export const price_list_frequencies_as_table = [
    ['1 weeks', 'Weekly'],
    ['2 weeks', 'Bi-weekly'],
    ['1 months', 'Monthly'],
    ['3 months', 'Quarterly'],
    ['1 years', 'Yearly'],
    ['never', 'Never'],
    ['other', 'Other'],
]

export let region_drop_down_data = {}

export const sales_promotions = [
    [1, 'Scooter Giveaway'],
    [2, 'November discount'],
    [3, 'June discount'],
    [4, 'Smartwatch giveaway'],
    [5, 'Earbud promotion'],
    [6, 'NL Action 2023'],
    [7, 'Coffee giveaway 2024'],
]

export const supplier_portal_levels = [
    ['demo', 'Demo'],
    ['trial', 'Trial'],
    ['normal', 'Normal'],
    ['trusted', 'Trusted'],
]

export const supplier_portal_customs_visibility = [
    ['T1', humanize_customs_visibility['T1']],
    ['T2', humanize_customs_visibility['T2']],
    ['both', humanize_customs_visibility['both']],
    ['hidden', humanize_customs_visibility['hidden']],
]

export const supplier_transport_costs_payment_methods = [
    ['nopay', 'Does not pay transport costs.'],
    ['include', 'Include transport costs in sales price per case'],
    ['separate', 'Add transport costs as a separate line on the invoice'],
]

// Hardcoded references to same supplier names which are used in logic
// elsewhere. Until a generic way is implemented to treat processes differently
// based on certain suppliers, this solution will do fine.
export const a2bc_supplier_name = 'A2BC Travel Retail BV'
export const msi_supplier_name = 'Moving Spirits International BV'
export const msp_supplier_name = 'Moving Spirits Purchasing BV'

export const scroll_states = {}

export let contact_person = {}
export let vat_rate = {}
export let vat_type = {}
export let payment_term = {}
export let insurance_type = {}
export let item_tag_category = {}
export let item_tag = {}
export let ledgers = {}
export let warehouses = {}
export const incoterms = []

export function bind() {
    events.on('identity.login', async() => {
        load_models()
    })
}

export async function load_models() {
    region_drop_down_data = new RegionDropDownData()
    exact_keys = new ExactKeys()
    vat_rate = new VatRateDataModel()
    vat_type = new VatTypeDataModel()
    contact_person = new ContactPersonDataModel()
    payment_term = new PaymentTermDataModel()
    insurance_type = new InsuranceTypeDataModel()
    item_tag_category = new ItemTagCategoryDataModel()
    ledgers = new LedgersDataModel()
    warehouses = new DestinationsDataModel()
    item_tag = new ItemTagDataModel()

    const {result, status_code} = await api.get('discover/data/incoterms')
    if (status_code > 299) {
        return
    }
    incoterms.splice(0, incoterms.length, ...result.map((i) => [i.code, `${i.code} - ${i.description}`]))
}

export function refresh() {
    load_models()
}
