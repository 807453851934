{Account} = require '@/models/accounts'
{User} = require '@/models/users'
app = require('@/app')

export class Supplier
    (json) ->
        {Ledger, PaymentTerm} = require '@/models/data'
        @artkey = window.prop ''
        @name = window.prop ''
        @street_address = window.prop ''
        @zip_code = window.prop ''
        @city = window.prop ''
        @country_code = window.prop ''
        @region_code = window.prop ''
        @emailaddress = window.prop ''
        @telephone_number = window.prop ''
        @url = window.prop ''
        @purchase_manager = window.prop new User
        @sales_manager = window.prop new User
        @price_list_manager = window.prop new User
        @company_type = window.prop ''
        @client_status = window.prop ''
        @operates_online = window.prop null
        @price_list_frequency = window.prop ''
        # Added by factserver, not from DB
        @is_supplier = window.prop false
        @is_buyer = window.prop false
        @incoterm = window.prop null
        @purchase_ledger = window.prop Ledger
        @purchase_ledger_artkey = window.prop ''
        @sales_ledger = window.prop new Ledger
        @sales_ledger_artkey = window.prop ''
        @low_tariff_sales_ledger = window.prop new Ledger
        @low_tariff_sales_ledger_artkey = window.prop ''
        @relation_nr = window.prop ''
        @vat_id = window.prop ''
        @excise_id = window.prop ''
        @memo = window.prop ''
        @should_receive_offer_mails = window.prop false
        @should_receive_purchase_mails = window.prop false
        @purchase_payment_term = window.prop new PaymentTerm
        @purchase_payment_term_artkey = window.prop ''
        @sales_payment_term = window.prop new PaymentTerm
        @sales_payment_term_artkey = window.prop ''
        @sales_account = window.prop new Account
        @sales_account_artkey = window.prop ''
        @portal_level = window.prop null
        @portal_customs_visibility = window.prop null
        @mix_customs_on_pricelist = window.prop false
        @portal_account_expiry = window.prop null
        @currency = window.prop 'EUR'
        @customs_status = window.prop 'T1'
        @transport_costs_per_case = window.prop null
        @transport_costs_payment_method = window.prop null
        @include_ukds_in_price = window.prop false
        @show_excise_in_portal = window.prop false
        @include_excise_in_price = window.prop false
        @minimum_order_amount = window.prop null
        @price_markup_percentage = window.prop null
        @pays_transport_costs = window.prop false
        @has_purchase_orders = window.prop false  # added by factserver, not from DB
        @has_sales_orders = window.prop false  # added by factserver, not from DB
        @primary_warehouse_artkey = window.prop null
        @use_incoterm_on_pricelist = window.prop false
        @price_preference = window.prop 'case'
        @active_promotion = window.prop null
        @promotion_turnover_draft = window.prop null
        @emailaddress_rfp_documents = window.prop null
        @emailaddress_financial_documents = window.prop null
        @exclude_from_pricelist_for_countries = window.prop null
        @exclude_from_pricelist_for_suppliers = window.prop null

        @longitude_latitude_checked_on = window.prop null
        @longitude = window.prop null
        @latitude = window.prop null

        @linkedin = window.prop ''
        @facebook = window.prop ''
        @instagram = window.prop ''
        @twitter = window.prop ''
        @relation_price_list_generated_on = window.prop ''

        @from_json(json)

    from_json: (json) ->
        for prop of json
            if prop == 'purchase_manager'
                @purchase_manager app.$m.users.create_user json[prop]
            else if prop == 'sales_manager'
                @sales_manager app.$m.users.create_user json[prop]
            else if prop == 'price_list_manager'
                @price_list_manager app.$m.users.create_user json[prop]
            else if prop == 'sales_account'
                @sales_account_artkey json[prop].artkey
                @sales_account new Account json[prop]
            else if prop == 'purchase_ledger'
                @purchase_ledger_artkey json[prop].artkey
                @purchase_ledger app.$m.data.ledgers.create_ledger json[prop]
            else if prop == 'sales_ledger'
                @sales_ledger_artkey json[prop].artkey
                @sales_ledger app.$m.data.ledgers.create_ledger json[prop]
            else if prop == 'low_tariff_sales_ledger' and json[prop]
                @low_tariff_sales_ledger_artkey json[prop].artkey
                @low_tariff_sales_ledger app.$m.data.ledgers.create_ledger json[prop]
            else if prop == 'sales_payment_term' and json[prop]
                @sales_payment_term_artkey +json[prop].artkey
                @sales_payment_term app.$m.data.payment_term.create_payment_term json[prop]
            else if prop == 'purchase_payment_term' and json[prop]
                @purchase_payment_term_artkey +json[prop].artkey
                @purchase_payment_term app.$m.data.payment_term.create_payment_term json[prop]
            else if prop == 'exclude_from_pricelist_for_suppliers' and json[prop]
                @exclude_from_pricelist_for_suppliers json[prop]
            else if @[prop]
                @[prop](json[prop])
            else
                @[prop] = window.prop json[prop]


export class SupplierPriceList
    (artkey, supplier, created_on, start_date, end_date, \
     file_name, is_current, header, header_as_list, number_of_items, \
     number_of_unresolved_source_lines,
     number_of_unresolved_expert_source_lines_count, \
     number_of_blacklisted_source_lines, \
     file_hash, description, status, source_lines, always_active) ->
        @artkey = window.prop artkey
        @created_on = window.prop created_on
        @start_date = window.prop start_date
        @end_date = window.prop end_date
        @file_name = window.prop file_name
        @is_current = window.prop is_current
        @header = window.prop header
        @header_as_list = window.prop header_as_list
        @number_of_items = window.prop number_of_items
        @number_of_unresolved_source_lines = window.prop number_of_unresolved_source_lines
        @number_of_unresolved_expert_source_lines = window.prop number_of_unresolved_expert_source_lines_count
        @number_of_blacklisted_source_lines = window.prop number_of_blacklisted_source_lines
        @file_hash = window.prop file_hash
        @description = window.prop description || ''
        @status = window.prop status
        @supplier = prop(if supplier then new Supplier supplier else new Supplier)
        @always_active = prop(status == SupplierPriceListStatus.ALWAYS_ACTIVE)
        if source_lines
            @source_lines = window.prop [new SupplierPriceListSourceLine sl for sl in source_lines]


export class SupplierPriceListsDataModel
    ->

    create: (supplier_price_list_json) ~>
        return new SupplierPriceList supplier_price_list_json.artkey,
            supplier_price_list_json.supplier, supplier_price_list_json.created_on,
            supplier_price_list_json.start_date, supplier_price_list_json.end_date,
            supplier_price_list_json.file_name, supplier_price_list_json.is_current,
            supplier_price_list_json.header, supplier_price_list_json.header_as_list,
            supplier_price_list_json.supplier_price_list_items_count,
            supplier_price_list_json.unresolved_source_lines_count,
            supplier_price_list_json.unresolved_expert_source_lines_count,
            supplier_price_list_json.suppressed_source_line_count,
            supplier_price_list_json.file_hash,
            supplier_price_list_json.supplier_price_list_description,
            supplier_price_list_json.supplier_price_list_status,
            supplier_price_list_json.supplier_price_list_source_lines,
            supplier_price_list_json.always_active


export class SupplierPriceListItem
    (json) ->
        {Bottle} = require '@/models/bottles'
        @artkey = window.prop ''
        @supplier_price_list = window.prop new SupplierPriceList
        @supplier_price_list_artkey = window.prop ''
        @bottle = window.prop new Bottle
        @bottle_artkey = window.prop ''
        @bottle__product__name = window.prop ''
        @bottle__product__default_country_code = window.prop ''
        @bottle__product__category = window.prop ''
        @bottle__volume = window.prop ''
        @bottle__alcohol_percentage = window.prop ''
        @bottle__refill_status = window.prop ''
        @product__product_bottle_type = window.prop ''
        @currency = window.prop ''
        @country_of_origin = window.prop ''
        @number_of_bottles = window.prop ''
        @price_per_case = window.prop ''
        @number_of_bottles_per_case = window.prop ''
        @aux_info = window.prop ''
        @gift_box_type = window.prop ''
        @number_of_cases = window.prop ''
        @availability_date = window.prop ''
        @price_per_bottle = window.prop ''
        @availability_status = window.prop ''
        @bottle_gtin_code = window.prop ''
        @bottle_gtin = window.prop ''
        @case_gtin_code = window.prop ''
        @incoterm = window.prop ''
        @customs_status = window.prop ''
        @number_of_matching_tbo_items = window.prop 0
        @suppliers_item_code = window.prop ''
        @suppliers_hs_code = window.prop ''
        @case_gross_weight_in_kg = window.prop ''
        @cases_per_pallet_layer = window.prop ''
        @cases_per_pallet = window.prop ''

        for prop of json
            if prop == 'supplier_price_list'
                @supplier_price_list new app.$m.spls.create json[prop]
                @supplier_price_list_artkey @supplier_price_list!artkey
            else if prop == 'bottle'
                @bottle_artkey json[prop].artkey
                @bottle app.$m.bottles.create_bottle json[prop], app.$m.products.create_product json[prop]['product']
            else
                @[prop] = window.prop json[prop]

    set_bottle: (bottle) ~>
        @bottle_artkey bottle.artkey!
        @bottle__product__name bottle.product!name!
        # (!) weare.product_categories doesn't exist; assumption: this function call never happens
        @bottle__product__category weare.product_categories[bottle.product!category!toLowerCase!]
        @bottle__volume bottle.volume!
        @bottle__alcohol_percentage bottle.alcohol_percentage!
        @bottle__refill_status bottle.refill_status!

    reset_values: ~>
        {Bottle} = require '@/models/bottles'
        @artkey ''
        @bottle new Bottle
        @bottle_artkey ''
        @bottle__product__name ''
        @bottle__product__category ''
        @bottle__volume ''
        @bottle__alcohol_percentage ''
        @bottle__refill_status ''
        @currency ''
        @number_of_bottles ''
        @price_per_case ''
        @number_of_bottles_per_case ''
        @aux_info ''
        @gift_box_type ''
        @number_of_cases ''
        @availability_date ''
        @price_per_bottle ''
        @availability_status ''
        @bottle_gtin_code ''
        @case_gtin_code ''
        @incoterm ''
        @customs_status ''
        @number_of_matching_tbo_items 0
        @cases_per_pallet ''
        @cases_per_pallet_layer ''

    to_json: ~> do
        artkey: @artkey!
        supplier_price_list_artkey: @supplier_price_list!artkey!
        currency: @currency!
        number_of_bottles: @number_of_bottles!
        price_per_case: @price_per_case!
        number_of_bottles_per_case: @number_of_bottles_per_case!
        aux_info: @aux_info!
        gift_box_type: @gift_box_type!
        number_of_cases: @number_of_cases!
        availability_date: @availability_date!
        price_per_bottle: @price_per_bottle!
        availability_status: @availability_status!
        bottle_gtin: @bottle_gtin!
        bottle_gtin_code: @bottle_gtin_code!
        case_gtin_code: @case_gtin_code!
        incoterm: @incoterm!
        customs_status: @customs_status!
        bottle:
            artkey: @bottle!artkey!
            volume: @bottle!volume!
            alcohol_percentage: @bottle!alcohol_percentage!
            refill_status: @bottle!refill_status!
            product_artkey: @bottle!product_artkey!
            product:
                name: @bottle!product!name!
                category: @bottle!product!category!
                artkey: @bottle!product!artkey!


export class SupplierPriceListStatus
    @ACTIVE = 'active'
    @ALWAYS_ACTIVE = 'always_active'
    @DELETED = 'deleted'


export class SupplierPriceListSourceLine
    (json) ->
        @artkey = window.prop ''
        @supplier_price_list = window.prop new SupplierPriceList
        @supplier_price_list_artkey = window.prop ''
        @line_content = window.prop ''
        @currency = window.prop ''
        @country_of_origin = window.prop ''
        @refill_status = window.prop ''
        @aux_info = window.prop ''
        @product_name = window.prop ''
        @availability_date = window.prop ''
        @number_of_bottles = window.prop ''
        @price_per_case = window.prop ''
        @number_of_cases = window.prop ''
        @alcohol_percentage = window.prop ''
        @volume = window.prop ''
        @gift_box_type = window.prop ''
        @price_per_bottle = window.prop ''
        @availability_status = window.prop ''
        @is_matched_as = window.prop new SupplierPriceListItem
        @is_matched_as_artkey = window.prop ''
        @number_of_bottles_per_case = window.prop ''
        @incoterm = window.prop ''
        @customs_status = window.prop ''
        @product_map_artkey = window.prop ''
        @product_map_key = window.prop ''
        @category = window.prop ''
        @bottle_gtin_code = window.prop ''

        for prop of json
            if prop == 'supplier_price_list'
                @supplier_price_list = window.prop app.$m.spls.create json[prop]
                @supplier_price_list_artkey json[prop].artkey
            else if prop == 'is_matched_as' and json[prop]
                @is_matched_as = new SupplierPriceListItem json[prop]
                @is_matched_as_artkey json[prop].artkey
            else
                @[prop] = window.prop json[prop]

