m = require 'mithril'
{map, obj-to-pairs, sort-by} = require 'prelude-ls'
{button-with-icon} = require '@/components/buttons.ls'
{icon} = require '@/components/icon.ls'
AutoComplete = require '@/components/collection/autocomplete.ls'
{copy, prepend, to-string}: utils = require 'utils.ls'
{current_account_slug} = require '@bitstillery/common/account/account'
{deref} = require '@bitstillery/common/utils.ls'
{classes, hash} = require '@bitstillery/common/lib/utils'
{countries} = require '@bitstillery/common/lib/countries'
{languages} = require './languages.ls'
{Bottle} = require '@/models/bottles'
{next_tick} = require '@bitstillery/common/lib/proxy'

app = require('@/app')

inputs = {}
module.exports = inputs


inputs.field = (label, input, label_width=2, input_width=10, help) ->
    m '.field',
        m "label" label
        input
        help ? m ".help" help


inputs.gift_box_type = (gift_box_type, options) ->
    if not options then options = {}
    update_gb = (value) ->
        gift_box_type value
        if options.after_update?
            options.after_update!

    m '.c-field-gift-box field',
        if options.label then m 'label' options.label
        m 'select', do
            onchange: (ev) ~> update_gb ev.target.value
            required: options.required
            disabled: options.disabled
        , [
            m 'option' {value: ''} 'Select Gift box type...'
            if options.include_no_gift_box_type
                m 'option' {value: 'no_gift_box_type', selected: gift_box_type! == 'no_gift_box_type'} 'No gift box'
            app.$m.data.gift_box_types.map (gbt) ->
                m 'option', {value: gbt, selected: gift_box_type! == gbt} gbt
        ]


inputs.customs_status = (customs_status, options) ->
    if not options then options = {}

    element = m 'select', do
        onchange: (ev) ~>
            if options.onchange
                options.onchange ev.target.value
            else if customs_status
                customs_status ev.target.value
        required: options.required
        disabled: options.disabled
    , [
        m 'option' {value: '', selected: true} '-'
        app.$m.data.customs_status.map (cs) ->
            m 'option' {value: cs, selected: cs == customs_status!} cs
    ]

    # Legacy field implementation; remove after common lib migration
    if !options.label
        return element

    validation = options.validation
    invalid = if validation then validation._invalid else false

    if validation && options.label then
        validation.description = options.label

    # New field format
    m classes('.c-field-customs-status.field', {
        disabled: options.disabled,
        invalid: validation && invalid && validation.dirty,
        valid: !validation || !invalid,
    }),
        m 'label',
            options.label
            if validation
                m 'span.validation' validation.label
        element
        if invalid && validation.dirty
            m '.help validation' if typeof invalid.message == 'function' then invalid.message! else invalid.message
        else if options.help
            m '.help' options.help

inputs.refill_status = (refill_status, options) ->
    if not options then options = {}
    m 'select', do
        onchange: (ev) ~> refill_status ev.target.value
        required: options.required
        disabled: options.disabled
    , [
        app.$m.data.bottle_refill_statusses.map (rs) ->
            m 'option' {value: rs, selected: rs == refill_status!} rs
    ]


inputs.text = (text, options) ->
    if not options then options = {}

    if options.type then
        type = options.type
    else
        type = 'text'

    disabled = options.disabled
    superuser_override_active = options.allow_superuser_override and app.$s.identity.user.name.is_superuser
    if superuser_override_active
        disabled = false

    validation = options.validation
    invalid = if validation then validation._invalid else false

    attrs = do
        type: type
        value: text!
        oninput: (ev) ~>
            if options.validation then
                options.validation.dirty = true

            text ev.target.value
        onblur: (ev) ~>
            if options.onblur then
                options.onblur(ev.target.value)

        required: options.required
        disabled: disabled
        placeholder: if options.placeholder then options.placeholder else ''
        pattern: options.pattern
        valid: if [false, true].includes(options.valid) then options.valid else true

    if options.after_update?
        attrs.onchange = options.after_update
    if options.id?
        attrs.id = options.id
    if options.autocomplete?
        attrs.autocomplete = options.autocomplete

    # Legacy field implementation; remove after common lib migration
    if !options.label
        return m '.input-group',
            m 'input.form-control' attrs
            if options.hint then m 'span.hint-text' options.hint else ''



    if validation && options.label then
        validation.description = options.label

    # New field format
    m classes('.c-field-text.field', {
        disabled: disabled,
        invalid: validation && invalid && validation.dirty,
        valid: !validation || !invalid,
    }),
        m 'label',
            if options.icon then [
                icon options.icon
                m.trust '&nbsp;'
            ]
            options.label
            if validation
                m 'span.validation' validation.label

        if options.addon
            m '.control',
                m 'input' attrs
                options.addon
        else
            m 'input' attrs

        if invalid && validation.dirty
            m '.help validation' if typeof invalid.message == 'function' then invalid.message! else invalid.message
        else if options.help
            m '.help' options.help


inputs.email = (text, options) ->
    if not options then options = {}
    options.type = 'email'
    inputs.text(text, options)


inputs.password = (text, options) ->
    if not options then options = {}
    options.type = 'password'

    validation = options.validation
    if validation && options.label then
        validation.description = options.label

    # Regex to make sure the new password is at least 8 char long,
    # with at least uppercase and lowercase letters.
    options.pattern =  if options.pattern then options.pattern else '(?=^.{8,}$)(?=.*[A-Z])(?=.*[a-z]).*$'
    inputs.text(text, options)


inputs.date = (date, options) ->
    if not options then options = {}
    element = m 'input' do
        required: options.required
        disabled: options.disabled
        type: 'date'
        value: if date! then utils.format_date_html5(date!) else ''
        oninput: (ev) ~> date ev.target.value
        min: if options.min then utils.format_date_html5(options.min) else "2000-01-01"
        max: if options.max then utils.format_date_html5(options.max) else "3000-01-01"

    # Legacy field implementation; remove after common lib migration
    if !options.label
        return element

    validation = options.validation
    invalid = if validation then validation._invalid else false

    # New field format
    m classes('.c-field-date field', {
        disabled: options.disabled,
        invalid: validation && invalid && validation.dirty,
        valid: !validation || !invalid,
    }),
        m 'label' options.label
        element
        if options.help
            m '.help' options.help


inputs.time = (time, options) ->
    if not options then options = {}

    if not options.label then
        m 'input.form-control' do
            required: options.required
            disabled: options.disabled
            value: time!
            type: 'time'
            oninput: (ev) ~> time ev.target.value

    m '.c-field-time field',
        m 'label' options.label
        m 'input' do
            required: options.required
            disabled: options.disabled
            value: time!
            type: 'time'
            oninput: (ev) ~> time ev.target.value


inputs.date_week = (date, options) ->
    if not options then options = {}
    m 'input.form-control' do
        required: options.required
        disabled: options.disabled
        type: 'week'
        value: if date! then date! else ''
        oninput: (ev) ~> date ev.target.valueAsDate


inputs.date_month = (date, options) ->
    if not options then options = {}
    m 'input.form-control' do
        required: options.required
        disabled: options.disabled
        type: 'month'
        value: if date! then date! else ''
        oninput: (ev) ~> date ev.target.valueAsDate


inputs.textarea = (textarea, options) ->
    if not options then options = {}
    element = m 'textarea', {
        required: options.required
        disabled: options.disabled
        rows: (options.rows || 4)
        value: textarea!
        oninput: (ev) ~>
            if options.validation then
                options.validation.dirty = true
            if options.oninput then
                options.oninput ev
            textarea ev.target.value
    }

    # Legacy element
    if !options.label
        return element

    validation = options.validation
    invalid = if validation then validation._invalid else false
    if validation then
        validation.description = options.label

    # New field format
    m classes('.c-field-textarea.field', {
        invalid: validation && invalid && validation.dirty,
        valid: !validation || !invalid,
    }),
        m 'label',
            if options.icon then [
                icon options.icon
                m.trust '&nbsp;'
            ]
            options.label
            if validation
                m 'span.validation' validation.label
        element
        if invalid && validation.dirty
            m '.help validation' if typeof invalid.message == 'function' then invalid.message! else invalid.message
        else if options.help
            m '.help' options.help


inputs.tax_label = (tax_label, options={}) ->
    # Get a list of all tax label item tags.
    if app.$m.data.item_tag.length == 0
        return
    choices = []
    if app.$m.data.item_tag_category.categories!length > 0
        choices = app.$m.data.item_tag_category.tax_label_category!
            |> app.$m.data.item_tag.get_all_from_category
            |> map (.name!)
            |> prepend (if options.no_option then [options.no_option] else [])

    options.empty_option = true
    inputs.select tax_label, choices, options

inputs.vat_rates = (vat_rate, country_code, options={}) ->
    choices = app.$m.data.vat_rate.get_vat_rates_by_country(country_code)
        |> map ((vat_rate) -> [vat_rate.artkey!, vat_rate.percentage!])
        |> prepend (if options.no_option then [options.no_option] else [])

    options.empty_option = true
    inputs.select vat_rate, choices, options


inputs.Texteditor = class
    (vnode) ->
        {@text, @required, @after-update} = vnode.attrs
        @old-text = @text!

    check-if-text-changed: ~>
        # This triggers if the @text changed externally.
        if @text! != @old-text then
            @editor.summernote 'code', @text!
            @set-text @text!

    set-text: (value) ~>
        @old-text = value
        @text value
        if @after-update then
            @after-update value

    onchange: (value) ~>
        @set-text value

    oncreate: ->
        @editor = $('#summernote')
        @editor.summernote do
            placeholder: 'Email content'
            height: 340
            fontNames: ['Helvetica', 'Arial', 'Verdana', 'Courier New', 'Calibri', 'Times New Roman']
            fontNamesIgnoreCheck: ['Helvetica', 'Arial', 'Verdana', 'Courier New', 'Calibri', 'Times New Roman']
            toolbar: [
                ['style', ['bold', 'italic', 'clear']],
                ['font', ['strikethrough']],
                ['fontname', ['fontname']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol']],
                ['insert', ['link', 'table', 'picture']],
                ['misc', ['undo', 'redo']],
                ['view', ['codeview']]
            ]
            callbacks: do
                onInit: ~>
                    # insert empty text, causing the dropdowns in the toolbar to select the correct font-family and size.
                    # default font-family and size is specified in the scss.
                    $('#summernote').summernote('insertText', '');
                onChange: (contents, $editable) ~>
                    @onchange contents
                onPaste: (e) ~>
                    bufferText = ((e.originalEvent or e).clipboardData or window.clipboardData).getData('Text')
                    e.preventDefault!
                    document.execCommand('insertText', false, bufferText)

    view: (vnode) ->
        @check-if-text-changed!
        m '.texteditor',
            m 'textarea#summernote' do
                required: @required
                value: @text!

inputs.Quantity = class
    (vnode) ->
        {@quantity, @after_update} = vnode.attrs

    parse-quantity: (value) ~>
        if value == ''  then
            return '0'
        else
            return +value

    oninput: (value) ~>
        @quantity @parse-quantity value
        if @after_update then
            @after_update value

    view: (vnode) ->
        m 'input.form-control.number-input' do
            type: 'number'
            oninput: (ev) ~> @oninput ev.target.value
            min: 0
            value: @quantity!

            oncreate: (vnode) ->
                $ vnode.dom .on 'click', utils.stop-propagation!
                if vnode.attrs.focus then
                    element.focus!

inputs.number = (number, options) ->
    if not options then options = {}
    update_number = (value) ->
        previous_value = number!
        if value.length
            number(+value)
        else
            number(null)
        if options.after_update
            options.after_update(+value, +previous_value)

    element = m 'input' do
        required: options.required
        disabled: options.disabled
        value: if isNaN(parseFloat(number!)) then '' else +number!
        type: 'number'
        min: if options.min? then options.min else ''
        max: if options.max? then options.max else ''
        step: if options.step? then options.step else 1
        oninput: (ev) ~> update_number ev.target.value
        # Disable the default click handler. Somehow it displaces the
        # cursor on a click event.
        oncreate: (vnode) ->
            $ vnode.dom .on 'click' utils.stop-propagation!
            if options.focus? then $ vnode.dom .focus!

    # Old legacy format.
    if !options.label
        return element

    return m classes('.c-field-number field', options.classNames),
        m 'label' options.label
        element
        if options.help
            m '.help' options.help


inputs.checkbox = (prop, options={}) ~>
    # A clickable label (one that toggles the checkbox) requires
    # a unique id/name to link the checkbox and the label. Most
    # of the time, the label will be enough to generate a unique
    # id. Use the id attribute when multiple checkboxes share
    # the same label.
    this.id = null

    if options.id
        this.id = "_#{hash(options.id)}"
    else if options.label
        this.id = "_#{hash(options.label)}"

    validation = options.validation
    invalid = if validation then validation._invalid else false

    const attrs = {
        checked: prop!,
        disabled: options.disabled,
        onchange: -> prop (not prop!),
        type: 'checkbox'
    }

    if this.id then
        Object.assign(attrs, {id: this.id, name: this.id})

    m classes('.c-field-checkbox.field', options.className, {
        disabled: options.disabled,
        invalid: validation && invalid && validation.dirty,
        valid: !validation || !invalid,
    }),
        m '.control',
            m 'input', attrs
            if options.label
                m 'label' {for: this.id} options.label
        if options.help
            m '.help' options.help


inputs.account = (account_artkey, options) ->
    inputs.select account_artkey,
        {[account.artkey!, account.name!] for account in app.$m.accounts.accounts!}, options


inputs.language = (language_code, options) ->
    if not options then options = {}
    const element = m 'select', do
        required: options.required
        onchange: (ev) ~> language_code ev.target.value
    , [
        m 'option' {value: ''}
        for code, name of languages
            m 'option' {value: code, selected: code == language_code!} name
    ]

    # Legacy element
    if !options.label
        return element

    # New field format
    elements = m '.c-field-language field',
        m 'label' options.label
        element
        if options.help
            m '.help' options.help

    return elements


inputs.specs = (bottles, bottle, options) ->
    if not options then options = {}
    set_bottle = (bottle_artkey) ->
        if bottle_artkey? and bottle_artkey != ''
            bottle bottles!find((bottle) -> bottle.artkey! == +bottle_artkey)
        else
            # Reset the bottle.
            bottle new Bottle
        if options.after_update?
            options.after_update if bottle_artkey? then bottle_artkey

    m '.c-field-specs field',
        if options.label then
            m 'label' options.label
        m 'select' do
            required: options.required
            onchange: (ev) ~> set_bottle ev.target.value
        , [
            m 'option' {value: ''} '-'
            if bottles!length > 0
                (sort-by ((obj) -> +(obj.volume!)), bottles!).map (b, index) ->
                    m 'option' {value: b.artkey!, selected: (if bottle!? then b.artkey! == bottle!artkey!)} b.to_specs!
        ]


inputs.radio = (value, choices, options) ->
    if not options then options = {}

    m '.btn-group' {'data-toggle': 'buttons'} [
        for choice in choices
            bound_value = (choice) ->>
                value(choice.value)
                await next_tick()
                await next_tick()
                m.redraw()

            name = options.name || utils.randomString(8)
            m(
                'label.btn', do
                    onclick: bound_value.bind(null, choice)
                    class: if value! == choice.value then 'btn-info' else 'btn-default'
                    title: choice.title
                , m('input', do
                    type: 'radio'
                    name: name
                    autocomplete: 'off'
                ), choice.description
            )
    ]


inputs.select = (value, choices, options) ->
    if not options then options = {
        tabindex: 0
    }
    attrs = do
        class: options.class
        required: options.required
        disabled: options.disabled
        tabindex: options.tabindex
        onchange: (ev) ~>
            if options.validation then
                options.validation.dirty = true

            if options.onchange
                options.onchange(ev.target.value)
            else if update_select
                update_select(ev.target.value)
    if options.id?
        attrs.id = options.id

    list-of-choices =
        switch typeof! choices
        | 'Object' =>
            # TODO can't guarantee order of choices like this.
            # TODO remove this in the future.
            obj-to-pairs choices
        | 'Array' =>
            choices
        | otherwise =>
            console.warn 'Invalid choices for inputs.select: ', choices

    update_select = (val) ->
        value val
        if options.after_update?
            options.after_update!

    element = m 'select' attrs, [
        if options.empty_option then
            m 'option' {value: '', selected: true} '-'
        else if not value! then
            # If the empty option is not allowed we should init 'value' with the
            # first choice (as in the list).
            first-key = list-of-choices[0]
            if typeof! first-key == 'Array'
                first-key = first-key[0]
            value first-key

        list-of-choices
        |> map (entry) ->
            if typeof! entry != 'Array'
            then [entry, entry]
            else entry
        |> map ([key, title]) ->
            m 'option' do
                value: key
                selected: (to-string key).toUpperCase() === (to-string value!).toUpperCase() # case insensitive string compare key.
            , title
    ]

    # Legacy field implementation; remove after common lib migration
    if !options.label
        return element

    validation = options.validation
    invalid = if validation then validation._invalid else false

    if validation && options.label then
        validation.description = options.label

    # New field format
    m classes('.c-field-select.field', {
        disabled: options.disabled,
        invalid: validation && invalid && validation.dirty,
        valid: !validation || !invalid,
    }),
        m 'label',
            if options.icon then [
                icon options.icon
                m.trust '&nbsp;'
            ]
            options.label
            if validation
                m 'span.validation' validation.label
        element
        if invalid && validation.dirty
            m '.help validation' if typeof invalid.message == 'function' then invalid.message! else invalid.message
        else if options.help
            m '.help' options.help

inputs.offer = (offers, offer_artkey, options) ->
    if not options then options = {}

    offer_name = (offer) ->
        if offer.remark then
            "#{offer.title} - #{offer.remark}"
        else
            offer.title

    m '.c-field-offer.field',
            # Legacy field implementation; remove after common lib migration
        m 'label' options.label
        m 'select', do
            required: options.required
            onchange: (ev) ~> offer_artkey ev.target.value
        , [
            m 'option' {value: ''}
            offers! |> sort-by (.title.toLowerCase!) |> map (offer) ~>
                m 'option' do
                    value: offer.artkey
                    selected: +offer.artkey == +offer_artkey!
                , offer_name offer
        ]

inputs.help = (text) ->
    m 'span.help-block' text
