import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {map, mergeAll, toArray} from 'rxjs/operators'
import {Modal} from 'components/modal/modal.ls'
import {proxy, type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'
import {Spinner} from '@bitstillery/common/components'

import {EditableRelationList, RelationsSearch} from '../components/relation'
import {FilterPresetType} from '../filter_preset/filter_preset'

import {DiscoverBottle, DiscoverTable, MarketItem} from './discover_table'

import {$m} from '@/app'
import {AddToOffer} from '@/market/pricelists/components/add_to_offer'
import {PagedCollectionFetcher} from '@/components/collection/collection_table'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {
    ApplyFilterButton,
    ArrayFilterHelper,
    CheckboxGroup,
    CheckboxGroupElement,
    CustomStatusFilterValues,
    FilterCheckboxGroupElement,
    FilterHelper,
    FilterPresetButton,
    FilterPresetHelper,
    FilterRadioButtonGroup,
    ObservableFilterHelper,
    RangedMinMaxFilter,
    RefillStatusFilterValues,
    SelectAllCheckboxGroupElement,
    SidebarLocalStorage,
    SingleValueFilterHelper,
} from '@/components/collection/side_bar'
import {
    GetAllProductCategoriesResponse,
    ProductCategoriesDropDownData,
    ProductCategory,
} from '@/factserver_api/product_categories'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {GetRelationsResponse} from '@/factserver_api/fact2server_api.ts'

interface DiscoverFilterAttrs {
    fetcher: PagedCollectionFetcher<DiscoverBottle>
}

class DiscoverFilter extends MithrilTsxComponent<DiscoverFilterAttrs> {
    data = proxy({
        selected_relations: [] as GetRelationsResponse[],
    })

    watchers: type_remove_watch_function[] = []

    all_filter_helpers: FilterHelper[]
    side_bar_storage: SidebarLocalStorage

    customs_status_filter_helper: SingleValueFilterHelper
    refill_status_filter_helper: SingleValueFilterHelper
    product_categories_filter_helper: ObservableFilterHelper<GetAllProductCategoriesResponse>
    alcohol_range_filter_helper: ArrayFilterHelper
    number_of_bottles_range_helper: ArrayFilterHelper
    volume_range_filter_helper: ArrayFilterHelper
    relation_filter_helper: ArrayFilterHelper
    gift_box_filter_helper: ArrayFilterHelper

    search_bar_control: SearchBarControl | null = null

    filter_preset_helper: FilterPresetHelper

    constructor(vnode: m.Vnode<DiscoverFilterAttrs>) {
        super()
        this.customs_status_filter_helper = new SingleValueFilterHelper(vnode.attrs.fetcher, 'customs_status', '')
        this.refill_status_filter_helper = new SingleValueFilterHelper(vnode.attrs.fetcher, 'refill_status', '')

        this.product_categories_filter_helper = new ObservableFilterHelper<ProductCategory>(
            vnode.attrs.fetcher,
            'show_categories',
            ProductCategoriesDropDownData.product_categories(),
            (category) => [category.artkey, category.name],
            [],
        )
        this.alcohol_range_filter_helper = new ArrayFilterHelper(vnode.attrs.fetcher, 'alcohol_range', [], ['', ''])
        this.number_of_bottles_range_helper = new ArrayFilterHelper(
            vnode.attrs.fetcher,
            'number_of_bottles_range',
            [],
            ['', ''],
        )
        this.volume_range_filter_helper = new ArrayFilterHelper(vnode.attrs.fetcher, 'volume_range', [], ['', ''])
        this.relation_filter_helper = new ArrayFilterHelper(vnode.attrs.fetcher, 'relation_artkeys', [], [])
        this.gift_box_filter_helper = new ArrayFilterHelper(vnode.attrs.fetcher, 'gift_box_types', [], [])

        this.all_filter_helpers = [
            this.customs_status_filter_helper,
            this.refill_status_filter_helper,
            this.product_categories_filter_helper,
            this.alcohol_range_filter_helper,
            this.number_of_bottles_range_helper,
            this.volume_range_filter_helper,
            this.relation_filter_helper,
            this.gift_box_filter_helper,
        ]

        this.side_bar_storage = new SidebarLocalStorage('discover-market-sidebar', this.all_filter_helpers)
        this.filter_preset_helper = new FilterPresetHelper(vnode.attrs.fetcher, FilterPresetType.MARKET_DISCOVER)

        this.watchers.push(watch(this.data.selected_relations, () => {
            this.relation_filter_helper.set_fetcher_filter_value(this.data.selected_relations.map((relation) => relation.artkey))
        }))
    }

    oncreate() {
        this.side_bar_storage.load()
    }

    onremove() {
        this.side_bar_storage.save()
        this.watchers.forEach((watch) => watch())
    }

    view(vnode: m.Vnode<DiscoverFilterAttrs>): m.Children {
        return [
            <FilterPresetButton helper={this.filter_preset_helper}/>,
            <ApplyFilterButton
                fetcher={vnode.attrs.fetcher}
                search_bar_control={null}
                filter_helpers={this.all_filter_helpers}
                side_bar_storage={this.side_bar_storage}
            />,
            <FilterRadioButtonGroup
                title={'Customs status'}
                helper={this.customs_status_filter_helper}
                filter_values={CustomStatusFilterValues}
            />,
            <FilterRadioButtonGroup
                title={'Refill status'}
                helper={this.refill_status_filter_helper}
                filter_values={RefillStatusFilterValues}
            />,
            <RangedMinMaxFilter
                title={'Number of bottles'}
                open_end={true}
                min={0}
                max={25}
                step={1}
                helper={this.number_of_bottles_range_helper}
            />,
            <RangedMinMaxFilter
                title={'Volume'}
                open_end={true}
                min={0}
                max={120}
                step={5}
                helper={this.volume_range_filter_helper}
            />,
            <RangedMinMaxFilter
                title={'Alcohol percentage'}
                open_end={false}
                min={0}
                max={100}
                step={5}
                helper={this.alcohol_range_filter_helper}
            />,
            <div className="field">
                <RelationsSearch
                    label={'Relation'}
                    selected_relations={this.data.selected_relations}
                    selected_relation_name={this.data.selected_relations[0]?.name}
                    search_bar_controller={(controller: SearchBarControl) =>
                        (this.search_bar_control = controller)
                    }
                />
                <EditableRelationList selected_relations={this.data.selected_relations} />
            </div>,

            <CheckboxGroup
                title={'Product category'}
                collapsed={true}
                helper={this.product_categories_filter_helper}
            >
                <SelectAllCheckboxGroupElement helper={this.product_categories_filter_helper}/>
                <CheckboxGroupElement
                    key_label$={ProductCategoriesDropDownData.product_categories().pipe(
                        mergeAll(),
                        map((a) => {
                            return {
                                artkey: a.artkey,
                                label: a.name,
                            }
                        }),
                        toArray(),
                    )}
                    helper={this.product_categories_filter_helper}
                />
            </CheckboxGroup>,

            <CheckboxGroup title={'Gift box type'} collapsed={true} helper={this.gift_box_filter_helper}>
                <SelectAllCheckboxGroupElement helper={this.gift_box_filter_helper}/>
                <FilterCheckboxGroupElement
                    filter_values={$m.data.gift_box_types.map((key: string) => {
                        return {
                            title: key,
                            value: key,
                        }
                    })}
                    helper={this.gift_box_filter_helper}
                />
            </CheckboxGroup>,
        ]
    }
}

export class MarketDiscover extends MithrilTsxComponent<unknown> {
    product_management_api = new ProductManagementApi()
    discover_fetcher = new PagedCollectionFetcher<DiscoverBottle>(
        'marketanalysis.get_market_items_per_bottle',
        'volume',
        undefined,
    )

    search_bar_controller: SearchBarControl | null = null
    show_add_to_offer = null

    oncreate(): void {
    }

    search_for_search_text = (search_text: string): void => {
        this.discover_fetcher.set_search_terms(search_text)
    }

    /** Translate the market item and the Discover bottle to a structure that the old AddToOffer understands. */
    open_add_to_offer_modal(market_item: MarketItem, discover_bottle: DiscoverBottle): void {
        if (market_item.is_available === false) {
            return
        }

        this.show_add_to_offer = {
            done: () => (this.show_add_to_offer = null),
            cancel: () => (this.show_add_to_offer = null),
            custom_offer: true,
            offer_artkey: null,
            oncancel: () => {
                this.show_add_to_offer = null
            },
            spli: () => {
                return {
                    artkey: () => market_item.artkey,
                    bottle: () => {
                        return {
                            artkey: () => discover_bottle.artkey,
                            to_specs: () => discover_bottle.volume,
                            product: () => {
                                return {
                                    name: () => discover_bottle.product.name,
                                }
                            },
                        }
                    },
                    aux_info: () => market_item.aux_info,
                    incoterm: () => market_item.incoterm,
                    price_per_case: () => market_item.price_per_case,
                    price_per_bottle: () => market_item.price_per_bottle,
                    number_of_cases: () => market_item.number_of_cases,
                    availability_status: () => market_item.availability_status,
                    currency: () => market_item.currency,
                    number_of_bottles: () => market_item.number_of_bottles_per_case * market_item.number_of_cases,
                    case_gift_box_type: () => market_item.gift_box_type,
                    customs_status: () => market_item.customs_status,
                    cases_per_pallet: () => market_item.cases_per_pallet,
                    cases_per_pallet_layer: () => market_item.cases_per_pallet_layer,
                    number_of_bottles_per_case: () => market_item.number_of_bottles_per_case,
                    supplier_price_list: () => {
                        return {
                            artkey: () => market_item.supplier_price_list.artkey,
                            supplier: () => {
                                return {
                                    name: () => market_item.supplier.name,
                                }
                            },
                        }
                    },
                }
            },
        }
    }

    view(): m.Children {
        return (
            <div className="c-market-discover filter-sidebar-wrapper view">
                <div className="c-filter-sidebar">
                    <DiscoverFilter fetcher={this.discover_fetcher} />
                </div>
                {this.show_add_to_offer && (
                    <Modal
                        title={'Add to custom offer'}
                        persistent={false}
                        onclose={() => (this.show_add_to_offer = null)}
                    >
                        <AddToOffer {...this.show_add_to_offer} />
                    </Modal>
                )}
                <div className={'filter-result'}>
                    <div className="c-filter-group">
                        <SearchBar
                            placeholder={'Search for name GTIN and/or category...'}
                            on_submit={this.search_for_search_text}
                            on_get_suggestions$={(filter_text: string) =>
                                this.product_management_api.get_simple_product_names(filter_text)
                            }
                            default_search_text={this.discover_fetcher.search_text()}
                            search_bar_controller={(controller: SearchBarControl) => {
                                this.search_bar_controller = controller
                                this.search_bar_controller.submit_search_text()
                            }}
                        />
                    </div>

                    {this.discover_fetcher.is_fetching && <Spinner />}

                    {
                        !this.discover_fetcher.is_fetching &&
                        this.discover_fetcher.has_fetched_at_least_once && (
                            <DiscoverTable
                                discover_fetcher={this.discover_fetcher}
                                open_add_to_offer_modal={(a: MarketItem, b: DiscoverBottle) =>
                                    this.open_add_to_offer_modal(a, b)
                                }
                            />
                        )
                    }
                    {
                        !this.discover_fetcher.is_fetching &&
                        this.discover_fetcher.has_fetched_at_least_once &&
                        this.discover_fetcher.no_more_results && (
                            <p>No more results</p>
                        )
                    }

                </div>
            </div>

        )
    }
}
