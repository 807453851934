import m from 'mithril'

import _models from './_pricelists.ls'

import {RefillStatus} from '@/factserver_api/fact2server_api.ts'

export const {
    OfferItem,
} = _models

export interface StockItem {
    item_artkey: number
    lot: string
    number_of_cases_available: number
    number_of_cases_in_purchase: number
    number_of_cases_in_sales: number
    reference: string
    stock_age_in_days: number
    was_bought_for_plus_costs: string
}

/**
 * Data model for SPLI items; reused for sourcelines => SPLI
 */
export interface SpliData {
    alcohol_percentage: number
    artkey: number
    aux_info: string | null
    availability_status: null
    bottle_artkey: number | null
    bottle_gtin_code?: string | null
    bottle_gtin_codes?: null | ''
    case_gtin_code: null
    cases_per_pallet?: null
    cases_per_pallet_layer?: null
    country_of_origin: string
    currency: string
    customs_status: string
    default_country_code: string
    euro_price_per_bottle: string
    euro_price_per_case: string
    gift_box_type: string
    incoterm: string
    incoterm_location?: string
    is_available: boolean
    /** Agregated total from stock_items */
    item_number_of_cases_available: number
    /** Agregated total from stock_items */
    item_number_of_cases_in_purchase: number
    /** Agregated total from stock_items */
    item_number_of_cases_in_sales: number
    /** The original pricelist excel line */
    line_content: string
    max_stock_age: number
    number_of_bottles?: null | number
    number_of_bottles_per_case: number
    number_of_cases?: null | number
    price_per_bottle?: null | number
    price_per_case?: null | number
    product_artkey: number
    product_category: string
    product_category_artkey: number
    product_name: string
    /** How well does this product perform, compared to competitors */
    rank: number
    refill_status: RefillStatus | null
    saved_purchase_order_artkey?: null | number
    saved_purchase_order_reference?: null | number
    spsl_artkey?: null | number
    /** How many of this resolved product do we have in stock */
    stock_items: StockItem[]
    /** Total amount of providers */
    total: number
    volume: number
}

export interface SourcelineData extends SpliData {
    availability_date?: string
    number_of_matching_tbo_items?: number
}

/**
 * SPLI model adapter for AddToOrder form. Next step is to
 * move all AddToOrder forms to the same spli_row_model.
 * @param spli
 * @returns
 */
export function adapter_add_to_order(spli, supplier) {
    return {
        artkey: () => spli.artkey,
        bottle: () => {
            return {
                artkey: () => spli.bottle_artkey,
                to_specs: () => `${spli.volume} / ${spli.alcohol_percentage} / ${spli.refill_status}`,
                product: () => {
                    return {
                        default_country_code: () => spli.default_country_code,
                        name: () => spli.product_name,
                    }
                },
            }
        },
        cases_per_pallet: () => spli.cases_per_pallet,
        cases_per_pallet_layer: () => spli.cases_per_pallet_layer,
        aux_info: () => spli.aux_info,
        incoterm: () => spli.incoterm,
        price_per_case: () => spli.price_per_case,
        price_per_bottle: () => spli.price_per_bottle,
        number_of_cases: () => spli.number_of_cases,
        availability_status: () => spli.availability_status,
        case_gift_box_type: () => spli.gift_box_type,
        currency: () => spli.currency,
        customs_status: () => spli.customs_status,
        number_of_bottles: () => spli.number_of_bottles_per_case * (spli.number_of_cases ?? 1),
        number_of_bottles_per_case: () => spli.number_of_bottles_per_case,
        supplier_price_list: () => {
            return {
                artkey: () => m.route.param('artkey'),
                supplier: () => {
                    return {
                        name: () => supplier.name,
                    }
                },
            }
        },
    }

}

/**
 * SPLI model adapter for AddToOffer form.
 * @param spli
 * @param supplier
 * @returns
 */
export function adapter_add_to_offer(spli, supplier) {
    return window.prop(adapter_add_to_order(spli, supplier))
}

/**
 * Data model for SpliEdit - using a new spli
 * @param model
 * @param spli
 */
export function spli_row_model() {
    return {
        alcohol_percentage: undefined,
        artkey: undefined,
        availability_status: undefined,
        aux_info: undefined,
        bottle_artkey: undefined,
        bottle_gtin_code: null,
        case_gtin_code: undefined,
        currency: 'EUR',
        customs_status: '',
        country_of_origin: '',
        default_country_code: '',
        gift_box_type: '',
        incoterm: 'EXW',
        incoterm_location: '',
        number_of_bottles: undefined,
        number_of_bottles_per_case: undefined,
        number_of_cases: undefined,
        cases_per_pallet: undefined,
        cases_per_pallet_layer: undefined,
        price_per_bottle: undefined,
        price_per_case: undefined,
        product_artkey: undefined,
        product_categories: [],
        product_category: '',
        product_category_artkey: undefined,
        product_name: '',
        refill_status: undefined,
        supplier_price_list_artkey: null,
        volume: undefined,
    }
}
