m = require 'mithril'
{join} = require 'prelude-ls'

{Amount} = require '@bitstillery/common/components'
{icon-button} = require '@/components/buttons.ls'
{Modal} = require '@/components/modal/modal.ls'
{Pager} = require '@/components/pager/pager.tsx'
{Panel} = require '@/components/panel'
{Popover} = require '@/components/popover/popover.ls'
table = require '@/components/table.ls'
{pricelist_link} = require 'data/components/pricelist_link.ls'

{a}: utils = require 'utils.ls'

{AddToOffer} = require '@/market/pricelists/components/add_to_offer'
{FilterType} = require './models.ls'
{SupplierPriceListItem} = require '@/models/suppliers'
{AmountUnit, Button, ButtonGroup, DataCard, Icon, Spinner, CollectionSorter} = require '@bitstillery/common/components'
{to_specs} = require '@bitstillery/common/lib/specs'

app = require('@/app')


export class ShowSupplierPriceListItems
    (vnode) ->
        @supplier_price_list_items = vnode.attrs.supplier_price_list_items
        @supplier_name = vnode.attrs.supplier_name
        @product_name = vnode.attrs.product_name
        @bottle_filters = vnode.attrs.bottle_filters
        @custom_offer_spli = window.prop null
        @collection = vnode.attrs.sort_collection

    # Constructs a link to discover with optional querystring parameters.
    discover_link: (product_name, volume, alcohol_percentage, supplier_name) ~>
        querystring = ""
        if product_name
            querystring = querystring + "#{product_name} "
        if volume
            querystring = querystring + "#{(+volume).toFixed(1)} "
        if alcohol_percentage
            querystring = querystring + "#{(+alcohol_percentage).toFixed(1)} "
        if supplier_name
            querystring = querystring + "#{supplier_name} "
        return "/market/discover?q=#{querystring}"

    open_custom_offer_modal: (spli) ~>
        # Clone spli so we don't change the original.
        spli_data = Object.assign {}, spli

        # Build a spli instance out of the spli_data.
        spli_data.bottle = do
            artkey: spli_data.bottle_artkey
            product: do
                artkey: spli_data.product_artkey
                name: spli_data.product_name
                category: spli_data.product_category
            alcohol_percentage: spli_data.bottle_alcohol_percentage
            refill_status: spli_data.bottle_refill_status
            volume: spli_data.bottle_volume
        spli_data.supplier_price_list = do
            artkey: spli_data.supplier_price_list_artkey
            supplier: do
                artkey: spli_data.supplier_artkey
                name: spli_data.supplier_name
        spli_data.number_of_bottles_per_case = spli_data.case_number_of_bottles

        @custom_offer_spli new SupplierPriceListItem spli_data

    close_custom_offer_modal: ~>
        @custom_offer_spli null

    view: (vnode) ->
        if vnode.attrs.count! > 0
            vnode.attrs.toolbar = m Pager, do
                page_size: vnode.attrs.limit!
                count: vnode.attrs.count!
                fetch_page: vnode.attrs.fetch_page
                display_page_count: false

        show_product = not (vnode.attrs.filter_type! in [FilterType.BOTH, FilterType.PRODUCT])
        show_relation = not (vnode.attrs.filter_type! in [FilterType.BOTH, FilterType.RELATION])
        show_specs = vnode.attrs.show_specs and not show_product

        if vnode.attrs.filter_type! == FilterType.BOTH
            # Show link for product and supplier.
            go_to_discover = [
                m m.route.Link, {
                    target: '_blank',
                    href: @discover_link(
                        @product_name!,
                        @bottle_filters!volume!,
                        @bottle_filters!alcohol_percentage!,
                        @supplier_name!,
                    )
                }, a do
                    m 'span.glyphicon.glyphicon-search',
                    m 'span' " For #{@supplier_name!}"

                # Show link only for product, not for supplier.
                m m.route.Link, {
                    target: '_blank',
                    href: @discover_link(
                        @product_name!,
                        @bottle_filters!volume!,
                        @bottle_filters!alcohol_percentage!,
                        null,
                    )
                }, a do
                    m 'span.glyphicon.glyphicon-search',
                    m 'span' ' For all suppliers'
            ]
        else if vnode.attrs.filter_type! == FilterType.RELATION
            # Show link only for supplier, not for product.
            go_to_discover = [
                m m.route.Link, {
                    target: '_blank',
                    href: @discover_link(
                        null,
                        null,
                        null,
                        @supplier_name!,
                    )
                }, a do
                    m 'span.glyphicon.glyphicon-search',
                    m 'span' " For #{@supplier_name!}"
            ]
        else
            # Show link only for product, not for supplier.
            go_to_discover = m m.route.Link, {
                target: '_blank',
                href: @discover_link(
                    @product_name!,
                    @bottle_filters!volume!,
                    @bottle_filters!alcohol_percentage!,
                    null,
                )
            }, a do
                m 'span.glyphicon.glyphicon-search'
                m 'span' ' For all suppliers'

        m '.c-market-explore-spli', {key: vnode.attrs.unique_name},
            m Panel, do
                title: vnode.attrs.title
                description: vnode.attrs.description
                toolbar: vnode.attrs.toolbar
                collapsible: vnode.attrs.collapsible
                collapsed: vnode.attrs.collapsed
                footer: vnode.attrs.footer
            ,
                if vnode.attrs.loading!
                    m Spinner
                else if @supplier_price_list_items!length
                    supplier_price_list_items = @supplier_price_list_items
                    [
                        m '.c-panel-body-header',
                            m DataCard, {
                                model: do
                                    data: [
                                        {label: 'Showing records for', value: vnode.attrs.header()},
                                        {label: 'Go to Discover', value: go_to_discover}
                                    ]
                                type: 'dense'
                            }
                            if @collection and @collection.state and @collection.state.ready
                                m CollectionSorter, do
                                    collection: @collection
                        table.table supplier_price_list_items, [
                            do
                                name: 'Relation'
                                function: (record) ->
                                    m '.td-group', [
                                        m 'span.header',
                                            if record.is_available === false
                                                m 's' do
                                                    class: 'analysis-bad-color'
                                                , record.supplier_name + ' '
                                            else
                                                record.supplier_name + ' '

                                        if show_specs then
                                            m 'span.details',
                                                to_specs({
                                                    bottle_volume: record.bottle_volume,
                                                    bottle_alcohol_percentage: record.bottle_alcohol_percentage,
                                                    bottle_refill_status: record.bottle_refill_status,
                                                    case_customs_status: record.customs_status,
                                                    case_gift_box_type: record.case_gift_box_type,
                                                    case_number_of_bottles: record.case_number_of_bottles,
                                                    case_tax_label: record.case_tax_label,
                                                })
                                        if record.aux_info then
                                            m 'span.details', record.aux_info
                                    ]
                                width: 13

                            if show_product then do
                                name: 'Product'
                                ellipsis: true
                                function: (record) ->
                                    record.product_name
                            do
                                classes: ['center']
                                name: 'Cases'
                                function: (spli) ->
                                    if spli.number_of_cases
                                        return spli.number_of_cases
                                    return '-'
                            do
                                name: 'Price'
                                function: (spli) ->
                                    if !spli.case_number_of_bottles || !parseInt(spli.price_per_case) then
                                        return '-'

                                    m AmountUnit, {
                                        case_amount: +spli.euro_price_per_case,
                                        case_number_of_bottles: spli.case_number_of_bottles,
                                        currency: app.$s.currencies.default,
                                        excise: +spli.case_excise_nl,
                                        origin: if app.$s.currencies.default != spli.currency then {
                                            case_amount: +spli.price_per_case,
                                            currency: spli.currency,
                                        } else undefined
                                    }
                            do
                                name: 'Availability'
                                function: (spli) ->
                                    if !spli.availability_status
                                        return '-'
                                    spli.availability_status
                            do
                                field: 'incoterm'
                                name: 'Incoterm'
                            do
                                name: 'Start date'
                                function: (spli) -> utils.format-date spli.supplier_price_list_start_date
                            do
                                name: 'End date'
                                function: (spli) -> utils.format-date spli.supplier_price_list_end_date
                            do
                                classes: 'actions'
                                function: (spli) ~>
                                    m '.actions-group no-click',
                                        m '.action-toggles',
                                            if spli.supplier_price_list_supplier_price_list_description
                                                m Icon, {
                                                    name: 'info',
                                                    tip: spli.supplier_price_list_supplier_price_list_description,
                                                    type: 'info',
                                                }

                                        m ButtonGroup,
                                            m Button, {
                                                disabled: spli.supplier_company_type == 'Competitor' || spli.is_available === false
                                                icon: 'copy',
                                                tip: ~>
                                                    if spli.is_available === false
                                                        'This pricelist item is not available'
                                                    else if spli.supplier_company_type == 'Competitor'
                                                        'This is a competitor'
                                                    else
                                                        'Add to custom offer'
                                                onclick: ~> @open_custom_offer_modal spli,
                                            }

                                            m Button, {
                                                onclick: ~>
                                                    spli_link = '#!' + pricelist_link.from_bottle_json(
                                                        spli.supplier_price_list_artkey,
                                                        do
                                                            volume: spli.bottle_volume
                                                            alcohol_percentage: spli.bottle_alcohol_percentage
                                                            product: {name: spli.product_name}
                                                    )
                                                    window.open(spli_link)
                                                icon: 'search',
                                                tip: "Search Pricelist #{spli.supplier_price_list_artkey} for #{spli.product_name}/#{spli.bottle_volume}/#{spli.bottle_alcohol_percentage}"
                                                type: 'info'
                                            }
                        ], do
                            search_table_style: false
                            sticky_header: false
                            row_class: (spli) -> app.$m.data.company_type_class spli.supplier_company_type

                        if @supplier_price_list_items!length > @to_show
                            if @show_all!
                            then m 'button.btn.btn-default' {onclick: @show_all.bind(@, false)} 'Show less'
                            else m 'button.btn.btn-default' {onclick: @show_all.bind(@, true)} 'Show all'

                        if @custom_offer_spli!
                            m Modal, do
                                title: 'Add to custom offer'
                                onclose: @close_custom_offer_modal
                            , m AddToOffer, do
                                spli: @custom_offer_spli
                                done: ~>
                                    @close_custom_offer_modal!
                                    app.notifier.notify 'Successfully added to custom offer.', 'success'
                                oncancel: @close_custom_offer_modal
                                custom_offer: true
                    ]
                else
                    if vnode.attrs.filter_type! in [FilterType.PRODUCT, FilterType.BOTH] then a do
                        m 'span' 'No results.'
                        m 'hr'
                        m m.route.Link, {
                            target: '_blank',
                            href: @discover_link(
                                @product_name!,
                                @bottle_filters!volume!,
                                @bottle_filters!alcohol_percentage!,
                                null,
                            )
                        } a do
                            m 'span.glyphicon.glyphicon-search'
                            m 'span' ' Go to discover to see prices for all suppliers.'
                    else
                        'No results.'
