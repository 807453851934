m = require 'mithril'
{join, sort-with} = require 'prelude-ls'
{filter, toArray, mergeAll, map} = require "rxjs/operators"
api = require 'api.ls'
{Amount} = require '@bitstillery/common/components'
{button-with-icon, icon-button, text-button} = require '@/components/buttons.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{Collection} = require '@/components/collection/collection.ls'
{icon, icon-with-popover} = require '@/components/icon.ls'
inputs = require '@/components/inputs'
{Modal} = require '@/components/modal/modal.ls'
{MultiInput} = require '@/components/multi-input/multi-input.ls'
{header_with_popover, Popover} = require '@/components/popover/popover.ls'
{rank} = require '@/components/rank.ls'
{RelationDropDown} = require '@/components/relation'
{pricelist_link} = require 'data/components/pricelist_link.ls'
{AddToOffer} = require '@/market/pricelists/components/add_to_offer'
{DiscoverTable} = require './discover_table'
{current_account_slug} = require '@bitstillery/common/account/account'
{RelationDropDownData} = require '@/factserver_api/relation_api'
{a, format-date, format-percentage, maybe-map, pluralize} = require 'utils.ls'
{Offer} = require '@/models/offers'
{AddPoiToOffer} = require './components/add_poi_to_offer'
{ProcessManageOffer} = require './processes'
{ProductFilterSidebar, ProductSearchMode} = require './components/product_selection_sidebar.ls'
{SearchBar, SearchBarControl} = require '@/components/collection/search_bar'
{ProductManagementApi} = require '@/factserver_api/product_management_api'
{SupplierPriceListItem} = require '@/models/suppliers'
{account-icon-by-slug} = require 'accounts.ls'
app = require('@/app')

module.exports = class OfferSelectProducts
    ->
        # Offer details.
        @offer = window.prop new Offer
        @offer_artkey = m.route.param 'artkey'

        # Offer item selection.
        @offer_item_list = []
        @keep_select_all_checked = window.prop false

        @saving = window.prop false

        @email_batch_artkey = m.route.param 'email_batch'

        @preview_supplier_artkey = window.prop ''
        @supplier_choices = window.prop []
        RelationDropDownData.relations().pipe(
            mergeAll(),
            map((relation) -> {
                name: -> relation.name
                artkey: -> relation.artkey
            }),
            toArray()
        ).subscribe((result) ~>
            @supplier_choices result
        )
        @hide_supplier_artkeys = window.prop []

        @search_mode = ProductSearchMode.OFFER

        @offer_items = new Collection do
            additional_params: @offer_items_params
            api_function_name: 'offer.get_offer_items_for_selection'
            query_limit: 50
            sort_order: [
                {'name': 'case_number_of_bottles', 'direction': 'asc'}
                {'name': 'bottle_volume', 'direction': 'asc'}
                {'name': 'bottle_alcohol_percentage', 'direction': 'asc'}
                {'name': 'bottle_refill_status', 'direction': 'desc'}
            ]
            default_sort_by: 'product_name'
            default_sort_order: 'asc'
            dont-reuse: true
            filter_serverside: true

        # Initialize offer item filters with their default values.
        if typeof @offer_items.filters == 'undefined'
            @offer_items.filters = {
                'refill_status': window.prop ''
                'tax_label': window.prop 'any'
                'customs_status': window.prop ''
                'hidden_selection': window.prop 'visible_only'
                'arriving_soon': window.prop false
                'categories': window.prop []
                'brands': window.prop []
                'gift_box_types': window.prop []
                'offer_item_types': window.prop ['stock', 'tbo', 'purchase']
                'alcohol_percentage_range': window.prop []
                'base_price_range': window.prop []
                'bottles_per_case_range': window.prop []
                'list_quantity_range': window.prop []
                'stock_age_range': window.prop []
                'last_price_change_age_days_range': window.prop []
                'volume_range': window.prop []
                'account_slugs': window.prop ['msi', 'msp', 'a2bc', 'etr']
            }

        @purchase_order_items = new Collection do
            additional_params: @purchase_order_items_params
            api_function_name: 'offer.get_purchase_order_items_for_selection'
            query_limit: 50
            sort_order: [
                {'name': 'case_number_of_bottles', 'direction': 'asc'}
                {'name': 'bottle_volume', 'direction': 'asc'}
                {'name': 'bottle_alcohol_percentage', 'direction': 'asc'}
                {'name': 'bottle_refill_status', 'direction': 'desc'}
            ]
            default_sort_by: 'product_name'
            default_sort_order: 'asc'
            dont-reuse: true
            filter_serverside: true

        # Initialize purchase order item filters with their default values.
        if typeof @purchase_order_items.filters == 'undefined'
            @purchase_order_items.filters = {
                'refill_status': window.prop ''
                'tax_label': window.prop 'any'
                'customs_status': window.prop ''
                'categories': window.prop []
                'brands': window.prop []
                'gift_box_types': window.prop []
                'alcohol_percentage_range': window.prop []
                'bottles_per_case_range': window.prop []
                'volume_range': window.prop []
            }

        @market_items = new Collection do
            additional_params: @market_items_params
            api_function_name: 'marketanalysis.get_market_items_per_bottle'
            query_limit: 50
            default_sort_by: 'product.name'
            default_sort_order: 'asc'
            dont-reuse: true
            filter_serverside: true

        # Initialize market item filters with their default values.
        if typeof @market_items.filters == 'undefined'
            @market_items.filters = {
                'refill_status': window.prop ''
                'customs_status': window.prop ''
                'categories': window.prop []
                'brands': window.prop []
                'gift_box_types': window.prop []
                'alcohol_percentage_range': window.prop []
                'bottles_per_case_range': window.prop []
                'volume_range': window.prop []
            }

        @add_purchase_order_item = window.prop null
        @add_market_item = window.prop null

        @search_bar_controller = null
        @product_management_api = new ProductManagementApi()
        @current_search_text = ""

    submit_search: (text) ~>
        @current_search_text = text
        if @search_mode == ProductSearchMode.PURCHASE
            @purchase_order_items.update_search_term text
            @purchase_order_items.submit_search!
        else if @search_mode == ProductSearchMode.OFFER
            @offer_items.update_search_term text
            @offer_items.submit_search!
        else if @search_mode == ProductSearchMode.MARKET
            @market_items.update_search_term text
            @market_items.submit_search!


    oncreate: ~>
        @query_offer @offer_artkey

        app.$m.common.observable.subscribe 'collection.offer.get_offer_items_for_selection.after-call', @, @handle_offer_items_query

    offer_items_params: ~>
        params = do
            offer_artkey: @offer_artkey
            supplier_artkey: @preview_supplier_artkey!
            hide_suppliers: @hide_supplier_artkeys!
            bottle_refill_status: @offer_items.filters['refill_status']!
            case_gift_box_types: @offer_items.filters['gift_box_types']!
            customs_status: @offer_items.filters['customs_status']!
            hidden_selection: @offer_items.filters['hidden_selection']!
            arriving_soon: @offer_items.filters['arriving_soon']!
            only_show_type: @offer_items.filters['offer_item_types']!
            show_accounts: @offer_items.filters['account_slugs']!
            show_categories: @offer_items.filters['categories']!
            show_brands: @offer_items.filters['brands']!
            bottle_alcohol_percentage_range: @offer_items.filters['alcohol_percentage_range']!
            bottle_volume_range: @offer_items.filters['volume_range']!
            case_number_of_bottles_range: @offer_items.filters['bottles_per_case_range']!
            list_quantity_range: @offer_items.filters['list_quantity_range']!
            price_per_case_range: @offer_items.filters['base_price_range']!
            stock_age_range: @offer_items.filters['stock_age_range']!
            last_price_change_age_days_range: @offer_items.filters['last_price_change_age_days_range']!

        # Add the tax label parameter if it is different from 'any'.
        tax_label = @offer_items.filters['tax_label']!
        if tax_label != 'any'
            params['case_tax_label'] = tax_label

        return params

    purchase_order_items_params: ~>
        params = do
            offer_artkey: @offer_artkey
            hide_suppliers: @hide_supplier_artkeys!
            bottle_refill_status: @purchase_order_items.filters['refill_status']!
            case_gift_box_types: @purchase_order_items.filters['gift_box_types']!
            customs_status: @purchase_order_items.filters['customs_status']!
            show_categories: @purchase_order_items.filters['categories']!
            show_brands: @purchase_order_items.filters['brands']!
            bottle_alcohol_percentage_range: @purchase_order_items.filters['alcohol_percentage_range']!
            bottle_volume_range: @purchase_order_items.filters['volume_range']!
            case_number_of_bottles_range: @purchase_order_items.filters['bottles_per_case_range']!

        # Add the tax label parameter if it is different from 'any'.
        tax_label = @purchase_order_items.filters['tax_label']!
        if tax_label != 'any'
            params['case_tax_label'] = tax_label

        return params

    market_items_params: ~>
        params = do
            offer_artkey: @offer_artkey
            hide_suppliers: @hide_supplier_artkeys!
            bottle_refill_status: @market_items.filters['refill_status']!
            case_gift_box_types: @market_items.filters['gift_box_types']!
            customs_status: @market_items.filters['customs_status']!
            show_categories: @market_items.filters['categories']!
            show_brands: @market_items.filters['brands']!
            bottle_alcohol_percentage_range: @market_items.filters['alcohol_percentage_range']!
            bottle_volume_range: @market_items.filters['volume_range']!
            case_number_of_bottles_range: @market_items.filters['bottles_per_case_range']!
            hide_internal_accounts: true

        return params

    check_all: ~>
        # Check all items in the search result. Note that we don't clear the
        # @offer_item_list, so that any previously checked items remain
        # checked.
        for offer_item in @offer_items.search_result!
            if offer_item.artkey not in @offer_item_list
                @offer_item_list.push offer_item.artkey

    check_offer_item: (offer_item_artkey) ~>
        if offer_item_artkey not in @offer_item_list
            @offer_item_list.push offer_item_artkey
        else
            index = @offer_item_list.indexOf(offer_item_artkey)
            if index > -1
                @offer_item_list.splice(index, 1)
        m.redraw!

    extract_rank: (record) ~>
        do
            rank: record.market_rank
            total: record.market_total

    handle_offer_items_query: ~>
        # Only check all items if the keep_select_all_checked flag is set.
        # This the case when checking the select all checkbox triggered the
        # show_all query.
        if @keep_select_all_checked!
            @check_all!
            @keep_select_all_checked false

    hide_suppliers: (value) ~>
        if value?
            @hide_supplier_artkeys value
            if @search_mode == ProductSearchMode.OFFER
                @offer_items.requery!
            else if @search_mode == ProductSearchMode.PURCHASE
                @purchase_order_items.requery!
            else if @search_mode == ProductSearchMode.MARKET
                @market_items.requery!
        else
            @hide_supplier_artkeys!

    products_are_loading: ~>
        if @search_mode == ProductSearchMode.OFFER
            @offer_items.loading!
        else if @search_mode == ProductSearchMode.PURCHASE
            @purchase_order_items.loading!
        else if @search_mode == ProductSearchMode.MARKET
            @market_items.loading!

    query_offer: (artkey) ~>
        data = do
            artkey: artkey
            include_suppliers: true

        api.call-and-then 'offer.get_offer' data, do
            success: (resp) ~>
                @offer new Offer resp.result

                # If the offer is for exactly one supplier: directly set it as the "preview supplier".
                # set_supplier also (re)queries the collection to fetch the prices for this supplier.
                if resp.result.suppliers.length == 1
                    @set_supplier resp.result.suppliers[0].artkey

                # Otherwise: just initialise the collection, without a preview supplier.
                else
                    if @search_mode == ProductSearchMode.OFFER
                        @offer_items.init!
                    else if @search_mode == ProductSearchMode.PURCHASE
                        @purchase_order_items.init!
                    else if @search_mode == ProductSearchMode.MARKET
                        @market_items.init!
            failure: ~>
                app.notifier.notify 'Unknown offer.', 'danger'
                m.route.set '/offer/offers'

    save: (e) ~>
        e.preventDefault!
        if not @saving!
            @saving true

            data = do
                artkey: @offer!artkey!
                offer_items: @offer_item_list

            api.call-and-then 'offer.select_offer_items', data, do
                success: ~>
                    app.$m.common.observable.broadcast 'offer_updated'
                    app.notifier.notify "Successfully selected offer items for offer \"#{@offer!title!}\".", 'success'

                    redirect_url = "/offer/offers/#{@offer_artkey}/custom_products"
                    if @email_batch_artkey
                        redirect_url += "/?email_batch=#{@email_batch_artkey}"
                    m.route.set redirect_url
                failure: (resp) ~>
                    app.notifier.notify resp.message, 'danger'
                    @saving false

    set_supplier: (supplier_artkey) ~>
        @preview_supplier_artkey "#{supplier_artkey}"

        if @search_mode == ProductSearchMode.OFFER
            @offer_items.requery!
        else if @search_mode == ProductSearchMode.PURCHASE
            @purchase_order_items.requery!

    toggle_all_offer_items: (value) ~>
        if value?
            if value
                # Check all offer items.
                if not @offer_items.showing_all!
                    # Before checking all offer items, execute a show_all
                    # query so they are all loaded. After that, the subscribe
                    # defined in the oncreate function ensures that the
                    # check_all function is automatically executed.
                    @keep_select_all_checked true
                    @offer_items.show_all!
                else
                    @check_all!
            else
                # Uncheck all offer items in the search result. Items outside
                # the search result keep being checked in the background.
                for item in @offer_items.search_result!
                    index = @offer_item_list.indexOf(item.artkey)
                    if index > -1
                        @offer_item_list.splice(index, 1)
        else
            # Check if the 'select all'-checkbox must be checked. If there is
            # no search result, or if (at least) one item in the search result
            # is not checked, the checkbox must be unchecked.
            if @offer_items.search_result!length == 0 then return false
            for item in @offer_items.search_result!
                if item.artkey not in @offer_item_list
                    return false

            # Otherwise, the checkbox must be checked.
            return true

    offer_search_mode: ~>
        @search_mode = ProductSearchMode.OFFER
        @submit_search @current_search_text
        @offer_items.requery!

    purchase_order_search_mode: ~>
        @search_mode = ProductSearchMode.PURCHASE
        @submit_search @current_search_text
        @purchase_order_items.requery!

    market_search_mode: ~>
        @search_mode = ProductSearchMode.MARKET
        @submit_search @current_search_text
        @market_items.requery!

    close_add_purchase_order_item_to_offer_modal: ~>
        @add_purchase_order_item null

    close_add_market_item_to_offer_modal: ~>
        @add_market_item null

    open_add_market_item_to_offer_modal: (market_item, bottle) ~>
        # Clone market_item so we don't change the original.
        spli_data = Object.assign {}, market_item

        # Build a spli instance out of the market item and the bottle.
        spli_data = market_item
        spli_data.bottle = do
            artkey: bottle.artkey
            product: bottle.product
            alcohol_percentage: bottle.alcohol_percentage
            refill_status: bottle.refill_status
            volume: bottle.volume
        spli_data.supplier_price_list.supplier = spli_data.supplier
        @add_market_item new SupplierPriceListItem spli_data

    view: ~> m '.c-process-new-offer-step-3 view process',
        m ProcessManageOffer, {
            active: 'select_products',
            context: {
                offer_artkey: @offer_artkey,
                offer_title: @offer!title!,
                email_batch_artkey: @email_batch_artkey
            }
        }

        m '.step-content',
            m '.filter-sidebar-wrapper' key: @search_mode,
                [
                    if @search_mode == ProductSearchMode.OFFER
                        m ProductFilterSidebar, do
                            collection: @offer_items
                            mode: ProductSearchMode.OFFER
                            key: 'offer_items_sidebar'

                    else if @search_mode == ProductSearchMode.PURCHASE
                        m ProductFilterSidebar, do
                            collection: @purchase_order_items
                            mode: ProductSearchMode.PURCHASE
                            key: 'purchase_order_items_sidebar'

                    else if @search_mode == ProductSearchMode.MARKET
                        m ProductFilterSidebar, do
                            collection: @market_items
                            mode: ProductSearchMode.MARKET
                            key: 'market_items_sidebar'
                ]  # this fragment wrapping is required so we can use keys to distinguish the two sidebars from each other

                m '.filter-result',
                    m '.c-filter-group',
                        m '.filter-field vertical',
                            m 'label' 'Optionally select a relation to preview its list prices'
                            if @offer!suppliers
                                get_all_response$ = RelationDropDownData.relations()
                                if @offer!suppliers!length > 0
                                    get_all_response$ = get_all_response$.pipe(
                                        mergeAll(),
                                        filter((relation) ~> @offer!suppliers!map((supplier) -> supplier.artkey).includes(relation.artkey)),
                                        toArray(),
                                    )

                                m RelationDropDown, do
                                    selected_relation_artkey: @preview_supplier_artkey!
                                    get_all_for_drop_down_response$: get_all_response$
                                    onchange: (supplier_artkey) ~>
                                        @set_supplier(supplier_artkey)
                                    disabled: @products_are_loading!


                    m '.c-filter-group',
                        m SearchBar,
                            placeholder: "Search products..."
                            on_submit: @submit_search
                            default_search_text: @offer_items.search_term!
                            search_bar_controller: (search_bar_controller) ~> @search_bar_controller = search_bar_controller
                            on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_product_names(filter_text)

                        m MultiInput, do
                            value: @hide_suppliers
                            choices: @supplier_choices
                            item-show: (.name!)
                            item-key: (.artkey!)
                            placeholder: 'Exclude items from suppliers'

                    m '.c-filter-group',
                        m '.btn-group',
                            text-button 'Offer items',
                                if @search_mode == ProductSearchMode.OFFER
                                    class: 'btn-primary'
                                else
                                    class: 'btn-default'
                                    onclick: ~> @offer_search_mode!
                            text-button 'Purchase order items',
                                if @search_mode == ProductSearchMode.PURCHASE
                                    class: 'btn-primary'
                                else
                                    class: 'btn-default'
                                    onclick: ~> @purchase_order_search_mode!
                            text-button 'Market items',
                                if @search_mode == ProductSearchMode.MARKET
                                    class: 'btn-primary'
                                else
                                    class: 'btn-default'
                                    onclick: ~> @market_search_mode!

                    m '.c-filter-group',
                        button-with-icon 'Save selection and proceed to customize items', 'glyphicon-list', do
                            class: 'btn-success'
                            disabled: @saving!
                            onclick: @save

                    if @search_mode == ProductSearchMode.MARKET then m '',
                        if @add_market_item! and @offer!artkey!
                            m Modal, do
                                title: 'Add market item to offer'
                                onclose: @close_add_market_item_to_offer_modal
                            , m AddToOffer, do
                                spli: @add_market_item
                                offer_artkey: @offer!artkey!
                                done: ~>
                                    @close_add_market_item_to_offer_modal!
                                    app.notifier.notify 'Successfully added the market item to the offer.', 'success'
                                oncancel: @close_add_market_item_to_offer_modal
                                custom_offer: true

                        m 'h3' 'Market items'

                        if @market_items.search_term! != ''
                            @market_items.show_counter!

                        m '.offer.offer-items',
                            if @market_items.no_results!
                                if @market_items.search_term! == ''
                                    m 'p' 'Enter a product name in the search field to start a new search.'
                            else if @market_items.queried!
                                m DiscoverTable, do
                                    bottles: @market_items
                                    open_add_to_offer_modal: @open_add_market_item_to_offer_modal

                    else if @search_mode == ProductSearchMode.PURCHASE then m '',
                        if @add_purchase_order_item! and @offer!artkey!
                            m Modal, do
                                title: 'Add purchase order item to offer'
                                onclose: @close_add_purchase_order_item_to_offer_modal
                            , m AddPoiToOffer, do
                                purchase_order_item: @add_purchase_order_item!
                                offer_artkey: @offer!artkey!
                                offer_title: @offer!title!
                                preview_supplier_artkey: @preview_supplier_artkey!
                                done: ~>
                                    @close_add_purchase_order_item_to_offer_modal!
                                    @purchase_order_items.requery!
                                    app.notifier.notify 'Successfully added the purchase order item to the offer.', 'success'
                                cancel: @close_add_purchase_order_item_to_offer_modal

                        m 'h3' 'Purchase order items'

                        @purchase_order_items.show_counter!

                        m CollectionTable, do
                            collection: @purchase_order_items
                            options:
                                search_table_style: true
                                sticky_header: true
                                with_buttons: false
                                autoscale: true
                                unique_name: 'purchase_order_item_list'
                                row_classes: (record) ->
                                    if record.offer_item_artkey? then ['is-hidden']
                            columns:
                                do
                                    width: 3
                                    header: '#'
                                    name: 'Purchase Order Reference'
                                    sort: true
                                    field: 'purchase_order_reference'
                                    function: (record) ->
                                        m m.route.Link, do
                                            target: '_blank'
                                            href: "/purchase-orders/manage/#{record.purchase_order_artkey}"
                                            class: 'no-click'
                                        , m '.fl-c', [
                                        account-icon-by-slug record.purchase_order_account_slug
                                        record.purchase_order_reference
                                        ]
                                do
                                    width: 4
                                    name: 'Article code'
                                    sort: true
                                    field: 'case_article_code'
                                    default_visible: false
                                do
                                    width: 12
                                    name: 'Product'
                                    sort: true
                                    field: 'product_name'
                                    ellipsis: true
                                do
                                    width: 6
                                    name: 'Category'
                                    sort: true
                                    field: 'product_category'
                                    ellipsis: true
                                    function: (record) ->
                                        m 'span.text-capitalize' record.product_category
                                do
                                    width: 6
                                    name: 'Brand'
                                    sort: true
                                    field: 'brand_name'
                                    ellipsis: true
                                do
                                    width: 8
                                    name: 'Supplier'
                                    sort: true
                                    field: 'supplier_name'
                                    ellipsis: true
                                do
                                    width: 4
                                    name: 'Btl / cs'
                                    sort: true
                                    field: 'case_number_of_bottles'
                                    default_visible: false
                                    classes: ['number']
                                do
                                    width: 4
                                    name: 'Size'
                                    sort: true
                                    field: 'bottle_volume'
                                    default_visible: false
                                    function: (record) ->
                                        m 'span' (+record.bottle_volume).toFixed(1), 'cl'
                                    classes: ['number']
                                do
                                    width: 4
                                    name: 'Alc %'
                                    sort: true
                                    field: 'bottle_alcohol_percentage'
                                    default_visible: false
                                    function: (record) ->
                                        m 'span' (+record.bottle_alcohol_percentage).toFixed(1), '%'
                                    classes: ['number']
                                do
                                    width: 3
                                    name: 'Ref'
                                    sort: true
                                    field: 'bottle_refill_status'
                                    default_visible: false
                                do
                                    width: 10
                                    name: 'Specs'
                                    function: (record) ->
                                        specs = []
                                        specs.push record.case_number_of_bottles
                                        specs.push (+record.bottle_volume).toFixed(1)
                                        specs.push (+record.bottle_alcohol_percentage).toFixed(1)
                                        specs.push record.bottle_refill_status
                                        join ' / ' specs
                                do
                                    width: 4
                                    name: 'GB'
                                    sort: true
                                    field: 'case_gift_box_type'
                                    default_visible: false
                                do
                                    width: 4
                                    name: 'Tax Label'
                                    sort: true
                                    field: 'case_tax_label'
                                    default_visible: false
                                do
                                    width: 8
                                    name: 'Features'
                                    function: (record) ->
                                        features = []
                                        if record.case_gift_box_type
                                            features.push record.case_gift_box_type
                                        if record.case_tax_label
                                            features.push record.case_tax_label
                                        if record.case_best_before_date
                                            features.push "BBD: #{formatDate record.case_best_before_date}"
                                        if record.case_damages
                                            features.push record.case_damages.replace(', ', ' / ')
                                        if record.case_general_tags
                                            features.push record.case_general_tags.replace(', ', ' / ')
                                        if record.case_pack_size
                                            features.push record.case_pack_size
                                        if record.case_packaging
                                            features.push record.case_packaging
                                        join ' / ' features
                                do
                                    width: 3
                                    name: 'Cases'
                                    field: 'number_of_cases'
                                    sort: true
                                    classes: ['number']
                                do
                                    width: 7
                                    name: 'SPLI Price / cs'
                                    field: 'was_bought_for'
                                    classes: ['price']
                                    function: (record) ->
                                        if record.refers_to_artkey then m '',
                                            if record.original_price_per_case then
                                                m Amount, do
                                                    amount: record.original_price_per_case
                                                    currency: record.original_currency
                                                    rate: record.bought_against_rate
                                            else if record.original_price_per_bottle then
                                                m Amount, do
                                                    amount: record.original_price_per_bottle * record.case_number_of_bottles
                                                    currency: record.original_currency
                                                    rate: record.bought_against_rate
                                            else
                                                '-'
                                        else
                                            '-'
                                do
                                    width: 8
                                    name: 'Net Price / cs'
                                    sort: true
                                    field: 'was_bought_for'
                                    classes: ['price']
                                    function: (record) ->
                                        price_per_bottle = record.was_bought_for / record.case_number_of_bottles

                                        m '',
                                            # Only show icon if the price is different from the SPLI.
                                            if record.refers_to_artkey and not (
                                                (record.original_price_per_case and +record.original_price_per_case == +record.was_bought_for) or
                                                (record.original_price_per_bottle and +record.original_price_per_bottle == +price_per_bottle)) then
                                                icon 'download', {style: {'margin-right': '5px'}}

                                            m Amount, do
                                                amount: record.was_bought_for
                                                currency: record.was_bought_in
                                                display_currency: app.$s.currencies.default
                                                rate: record.bought_against_rate

                                do
                                    width: 7
                                    name: 'Cost / cs'
                                    sort: true
                                    field: 'was_bought_for_plus_costs'
                                    classes: ['price']
                                    function: (record) ->
                                        m Amount, do
                                            amount: record.was_bought_for_plus_costs
                                            currency: record.was_bought_in
                                            rate: record.bought_against_rate
                                do
                                    width: 3
                                    header: 'Cus.'
                                    name: 'Customs status'
                                    sort: true
                                    field: 'case_customs_status'
                                do
                                    width: 3
                                    header: ''
                                    name: 'Actions'zg
                                    function: (record) ~>
                                        icon-button 'glyphicon-duplicate', do
                                            class: 'btn-default'
                                            onclick: ~> @add_purchase_order_item record
                                            disabled: record.offer_item_artkey?
                                        , do
                                            title: 'Add to offer'

                    else if @search_mode == ProductSearchMode.OFFER then m '',

                        @offer_items.show_counter!

                        m '.c-filter-group',
                            inputs.checkbox @toggle_all_offer_items, {
                                disabled: @offer_items.search_result!length == 0,
                                label: 'Select all'
                            }

                        m CollectionTable, do
                            collection: @offer_items
                            options:
                                search_table_style: true
                                sticky_header: true
                                with_buttons: false
                                autoscale: true
                                unique_name: 'offer_item_list'
                                row_classes: (record) ->
                                    if record.custom_offer_item_artkey? then ['is-hidden']

                            columns:
                                do
                                    width: 5
                                    name: 'Select for offer'
                                    function: (record) ~>
                                        m '',
                                            m 'input' do
                                                type: 'checkbox'
                                                id: record.artkey
                                                onclick: ~>
                                                    @check_offer_item record.artkey
                                                checked: record.custom_offer_item_artkey? or record.artkey in @offer_item_list
                                                disabled: record.custom_offer_item_artkey?
                                            m 'label' do
                                                for: record.artkey
                                do
                                    width: 4
                                    name: 'Rank'
                                    sort: true
                                    field: 'market_score'
                                    descending: true
                                    default_visible: false
                                    function: @extract_rank >> rank
                                do
                                    width: 4
                                    header: header_with_popover(
                                        "#{app.$s.currencies.default} above average price"
                                        a do
                                            m 'span.glyphicon.glyphicon-stats'
                                            m 'span' ' €'
                                    )
                                    name: "Above average (#{app.$s.currencies.default})"
                                    sort: true
                                    field: 'avg_competitor_diff'
                                    classes: ['price']
                                    descending: true
                                    default_visible: false
                                    function: (record) ->
                                        m Amount, do
                                            amount: +record.avg_competitor_diff
                                            currency: app.$s.currencies.default
                                            display_currency: app.$s.currencies.default
                                do
                                    width: 4
                                    header: header_with_popover(
                                        "#{app.$s.currencies.default} above average in %"
                                        a do
                                            m 'span.glyphicon.glyphicon-stats'
                                            m 'span' ' %'
                                    )
                                    name: 'Percent above average price'
                                    sort: true
                                    field: 'avg_competitor_diff_percentage'
                                    classes: ['number']
                                    descending: true
                                    default_visible: false
                                    function: (record) ->
                                        "#{(+record.avg_competitor_diff_percentage).toFixed(1)}%"
                                do
                                    width: 1
                                    name: 'Accounts'
                                    field: 'account_slug'
                                    function: (record) ~>
                                        if !record.account_slugs or record.account_slugs[0] == null
                                            m 'div' '-'
                                        else
                                            icons = []
                                            for account_slug in record.account_slugs
                                                icons.push account-icon-by-slug account_slug
                                            m 'div', icons
                                do
                                    width: 3
                                    name: 'Stock age'
                                    sort: true
                                    field: 'stock_age'
                                    function: (record) ->
                                        if record.stock_age is not null
                                            if record.stock_age == record.newest_stock_age
                                                record.stock_age
                                            else
                                                tooltiptext = "Oldest stock age: #{record.stock_age} days\nNewest stock age: #{record.newest_stock_age} days"
                                                m 'abbr' {title: tooltiptext} record.stock_age
                                        else
                                            '-'
                                do
                                    width: 5
                                    name: 'Last price change date'
                                    sort: true
                                    field: 'last_price_change_date'
                                    function: (record) ->
                                        if record.last_price_change_date is not null
                                            formatDate record.last_price_change_date
                                        else
                                            '-'
                                do
                                    width: 4
                                    name: 'Article code'
                                    field: 'case_article_code'
                                    sort: true
                                    default_visible: false
                                do
                                    width: 12
                                    name: 'Product'
                                    sort: true
                                    field: 'product_name'
                                    ellipsis: true
                                do
                                    width: 6
                                    name: 'Category'
                                    sort: true
                                    field: 'product_category'
                                    ellipsis: true
                                    function: (record) ->
                                        m 'span.text-capitalize' record.product_category
                                do
                                    width: 6
                                    name: 'Brand'
                                    sort: true
                                    field: 'brand_name'
                                    ellipsis: true
                                do
                                    width: 5
                                    name: 'TBO supplier'
                                    sort: true
                                    field: 'supplier_name'
                                    default_visible: false
                                    ellipsis: true
                                do
                                    width: 4
                                    name: 'Btl / cs'
                                    sort: true
                                    field: 'case_number_of_bottles'
                                    default_visible: false
                                    classes: ['number']
                                do
                                    width: 4
                                    name: 'Size'
                                    sort: true
                                    field: 'bottle_volume'
                                    default_visible: false
                                    function: (record) ->
                                        m 'span' (+record.bottle_volume).toFixed(1), 'cl'
                                    classes: ['number']
                                do
                                    width: 4
                                    name: 'Alc %'
                                    sort: true
                                    field: 'bottle_alcohol_percentage'
                                    default_visible: false
                                    function: (record) ->
                                        m 'span' (+record.bottle_alcohol_percentage).toFixed(1), '%'
                                    classes: ['number']
                                do
                                    width: 3
                                    name: 'Ref'
                                    sort: true
                                    default_visible: false
                                    field: 'bottle_refill_status'
                                do
                                    width: 10
                                    name: 'Specs'
                                    function: (record) ->
                                        specs = []
                                        specs.push record.case_number_of_bottles
                                        specs.push (+record.bottle_volume).toFixed(1)
                                        specs.push (+record.bottle_alcohol_percentage).toFixed(1)
                                        specs.push record.bottle_refill_status
                                        join ' / ' specs
                                do
                                    width: 4
                                    name: 'GB'
                                    sort: true
                                    field: 'case_gift_box_type'
                                    default_visible: false
                                do
                                    width: 4
                                    name: 'Tax Label'
                                    sort: true
                                    field: 'case_tax_label'
                                    default_visible: false
                                do
                                    width: 8
                                    name: 'Features'
                                    function: (record) ->
                                        features = []
                                        if record.case_gift_box_type
                                            features.push record.case_gift_box_type
                                        if record.case_tax_label
                                            features.push record.case_tax_label
                                        if record.case_best_before_date
                                            features.push "BBD: #{formatDate record.case_best_before_date}"
                                        if record.case_damages
                                            features.push record.case_damages.replace(', ', ' / ')
                                        if record.case_general_tags
                                            features.push record.case_general_tags.replace(', ', ' / ')
                                        if record.case_pack_size
                                            features.push record.case_pack_size
                                        if record.case_packaging
                                            features.push record.case_packaging
                                        join ' / ' features
                                do
                                    width: 3
                                    header: 'Cus.'
                                    name: 'Customs status'
                                    sort: true
                                    field: 'case_customs_status'
                                do
                                    width: 8
                                    name: 'Stock value'
                                    sort: true
                                    field: 'euro_total_stock_value'
                                    classes: ['price']
                                    default_visible: false
                                    function: (record) ->
                                        if record.offer_item_type != 'tbo'
                                            m Amount, do
                                                amount: +record.euro_total_stock_value
                                                currency: app.$s.currencies.default
                                        else
                                            '-'
                                do
                                    width: 2
                                    name: 'Number of cases in stock'
                                    header: icon-with-popover do
                                        icon-id: 'home'
                                        content: 'Number of cases in stock'
                                    sort: true
                                    field: 'number_of_cases_in_stock'
                                    classes: ['number']
                                    default_visible: false
                                do
                                    width: 2
                                    name: 'Number of cases in purchase'
                                    header: icon-with-popover do
                                        icon-id: 'shopping-cart'
                                        content: 'Number of cases in purchase'
                                    sort: true
                                    field: 'number_of_cases_in_purchase'
                                    classes: ['number']
                                    default_visible: false
                                do
                                    width: 2
                                    name: 'Number of cases in sales'
                                    header: icon-with-popover do
                                        icon-id: 'screenshot'
                                        content: 'Number of cases in sales'
                                    sort: true
                                    field: 'number_of_cases_in_sales'
                                    classes: ['number']
                                    default_visible: false
                                do
                                    width: 2
                                    name: 'Number of cases available'
                                    header: icon-with-popover do
                                        icon-id: 'fa-shield-alt'
                                        content: 'Number of cases available'
                                    sort: true
                                    field: 'number_of_cases_available'
                                    classes: ['number']
                                    default_visible: false
                                do
                                    width: 3
                                    name: 'Throughput'
                                    header: icon-with-popover do
                                        icon-id: 'flash'
                                        content: 'Throughput'
                                    field: 'item_throughput'
                                    sort: true
                                    classes: ['number']
                                    default_visible: false
                                    transform: maybe-map format-percentage
                                do
                                    width: 4
                                    header: 'MOQ'
                                    name: 'Minimum Order Quantity'
                                    field: 'minimum_quantity'
                                    sort: true
                                    classes: ['number']
                                    function: (record) ->
                                        if record.minimum_quantity? then
                                            record.minimum_quantity
                                        else
                                            '-'
                                do
                                    width: 4
                                    header: 'List Qty'
                                    name: 'List Quantity'
                                    field: 'list_quantity'
                                    sort: true
                                    classes: ['number']
                                do
                                    width: 8
                                    name: 'Avg purchase / cs'
                                    sort: true
                                    field: 'avg_purchase_price'
                                    classes: ['price']
                                    function: (record) ->
                                        if record.avg_purchase_price then
                                            m Amount, do
                                                amount: record.avg_purchase_price
                                                currency: app.$s.currencies.default
                                        else
                                            '-'
                                do
                                    width: 8
                                    name: 'Base price / cs'
                                    sort: true
                                    field: 'price_per_case'
                                    classes: ['price']
                                    function: (record) ~>
                                        m Amount, do
                                            amount: +record.price_per_case
                                            currency: app.$s.currencies.default
                                            rate: app.$s.currencies.exchange_rates[record.currency].portal_rate
                                do
                                    width: 8
                                    name: 'List price / cs'
                                    sort: true
                                    field: 'list_price'
                                    classes: ['price']
                                    function: (record) ~>
                                        m Amount, do
                                            amount: +record.list_price
                                            currency: record.currency
                                            rate: app.$s.currencies.exchange_rates[record.currency].portal_rate
                                do
                                    width: 8
                                    name: 'List price / btl'
                                    classes: ['price']
                                    default_visible: false
                                    function: (record) ~>
                                        m Amount, do
                                            amount: +record.list_price / +record.number_of_bottles
                                            currency: record.currency
                                            rate: app.$s.currencies.exchange_rates[record.currency].portal_rate
                                do
                                    width: 4
                                    name: 'Delivery period'
                                    sort: true
                                    field: 'delivery_period'
                                    classes: ['number']
                                    function: (record) ->
                                            if record.delivery_period? then
                                                record.delivery_period + ' ' + pluralize record.delivery_period, 'week', 'weeks'
                                            else
                                                '-'
                                do
                                    width: 8
                                    name: 'Item type'
                                    sort: true
                                    field: 'offer_item_type'
                                    transform: (type) ->
                                        | type == 'tbo' => 'TBO'
                                        | otherwise => type.capitalizeFirstLetter!
                                do
                                    width: 1
                                    name: 'Product photo icon'
                                    header: icon 'fa-image'
                                    sort: true
                                    field: 'has_product_photo'
                                    function: (record) ->
                                        if record.has_product_photo then
                                            icon-with-popover do
                                                icon-id: 'fa-image'
                                                title: 'Product photo'
                                                content: 'This product has one or more photos.'
                                do
                                    width: 1
                                    name: 'In active offer'
                                    header: ' '
                                    function: (record) ~>
                                        if record.exists_in_other_offers
                                            # Build the popover content string. The content cannot be
                                            # generated via Mithril because the Popover component
                                            # needs a rendered string, so we build the html manually.
                                            popover_content = '<ul>'
                                            for other_offer in record.exists_in_other_offers
                                                [artkey, reference] = other_offer.split(':', 2)
                                                popover_content += "<li><a target=\"_blank\" href=\"#!/offer/offers/#{artkey}/custom_products\">#{reference}</li>"
                                            popover_content += '</ul>'

                                            m Popover, do
                                                title: 'This product is currently offered in the following offers:'
                                                content: popover_content
                                            , m 'span.glyphicon.glyphicon-info-sign'
